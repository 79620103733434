import "./ButtonSideBar.scss"
import { Link } from "react-router-dom"
interface ButtonSideBarProps {
  text: string
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  color: string
  selected: boolean
  to: string
}
const ButtonSideBar = ({ text, onClick, color, selected, to }: ButtonSideBarProps) => {
  return (
    <Link
      className={`nbbl-button-container${color === "blue" ? " nbbl-blue" : ""} ${
        selected === true ? "nbbl-selected" : ""
      }`}
      style={{ border: "none" }}
      to={to}
      onClick={onClick}
    >
      <div className="nbbl-text-container">{text}</div>
    </Link>
  )
}

export default ButtonSideBar
