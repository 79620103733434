interface ArticlesKPIsProps {
  articles_sold: number
  invested_articles: number
}

export const ArticlesKPIs = ({ invested_articles, articles_sold }: ArticlesKPIsProps) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="nbbl-card-text"
          style={{
            fontSize: 24
          }}
        >
          Articles KPIS
        </div>
        <div className="nbbl-section-cont">
          <div className="nbbl-card-container" style={{ maxWidth: 272 }}>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              Total articles sold
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              {articles_sold}
            </div>
          </div>
          <div className="nbbl-card-container" style={{ maxWidth: 488 }}>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              Total revenue from articles sold last month
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              ${invested_articles}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
