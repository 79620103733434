import { Field, useField } from "formik"

export const Checkbox = ({ name }) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    helpers.setValue(checked)
  }
  return <Field type="checkbox" name={name} onChange={handleChange} />
}
