import axios from "axios"
import { APP_JSON_HEADER, APP_MULTIPART_HEADER, BASE_URL, successStatusCode } from "./config"

import EndPoints from "./endPoints"
import {
  IStripeIntentRegistration,
  IStripeResponse,
  ISubscriptionManagementMutation,
  ISubscriptionManagementParam
} from "./mutations"
import {
  ICreatePublisherSite,
  IPrivacyPolicy,
  IPublisherSite,
  ITermAndCondition,
  ITimesensitives,
  ItransactionHistory
} from "../utils/types/publisherSite"
import {
  IFrontendInfo,
  IIsAuthenticated,
  IIsAuthenticatedPayload,
  profileType
} from "../utils/types/isAuthenticated"
import {
  IAccountSetup,
  IArticleViewHistory,
  ICustomer,
  IDashboardModule
} from "../utils/types/accountSetup"
import {
  IPlaidAccountDetails,
  IPlaidAccountDetailsBody,
  IPlaidTokenLinkResponse
} from "../utils/types/plaid"
import { IPlan, ITransactionHistory, IUserPlan } from "../utils/types/userPlan"
import {
  IAttachPaymentMethod,
  IDetachPaymentMethod,
  IStripeCustomer,
  IStripeCustomerValidation,
  IStripeDefaultPaymentMethodPayload,
  IStripeDefaultPaymentMethodResponse,
  IStripePaymentMethod,
  IStripePaymentMethodsResponse,
  IStripeSetupIntent
} from "../utils/types/stripe"

const GET = "GET"
const POST = "POST"
const PUT = "PUT"
const DELETE = "DELETE"
const PATCH = "PATCH"

interface IRequestData<TResponse> {
  current_time: string
  payload: TResponse
  remote_address: string
  request_id: string
  status_code: number
  success: boolean
}

interface IPaginatedResponse<T> {
  count: number
  next: string
  previous: string
  results: T
  total_pages?: number
}

interface ISubscriptionManagement {
  id: number
  plans: IPlan[]
  time_sensitives: ITimesensitives[]
  hot_price_time: number
  hot_price: number
  publisher_site: IPublisherSite
}
type methodType = "GET" | "POST" | "PUT" | "DELETE" | "PATCH"

const catchErrors = (err: any) => {
  // check for 1xx, 3xx, 4xx, 5xx response codes
  const statusType = Math.floor((err?.response?.status || 0) / 100)
  if (statusType !== 2) {
    ;(window as any)?.customErrorCallback?.(humanReadableErrorMessage(err))
  }
  throw err
}

const humanReadableErrorMessage = (responseData: any) => {
  const responseDataPayload = responseData?.response?.data?.payload
  let guessedErrorMessage: any = []
  for (let item in responseDataPayload) {
    guessedErrorMessage.push(responseDataPayload[item])
  }
  guessedErrorMessage = guessedErrorMessage.join(". ")
  return responseDataPayload?.message || guessedErrorMessage
}

export async function request<TBody, TResponse>(
  endPoint: string,
  method: methodType,
  body?: TBody
): Promise<IRequestData<TResponse>> {
  console.log(`[API ${method?.toString()?.toUpperCase()}]`, endPoint)
  const header = await APP_JSON_HEADER()
  return await axios({
    method: method,
    url: BASE_URL + endPoint,
    data: body,
    headers: header,
    timeout: 150000
  })
    .then(function (response: any) {
      if (successStatusCode.includes(response?.status)) return response?.data
    })
    .catch((error: any) => {
      console.error(
        `[API ERR ${error?.response?.status}]`,
        humanReadableErrorMessage(error) || error
      )
      console.log(error)

      catchErrors(error)
    })
}

export async function requestMultipart<TBody, TResponse>(
  endPoint: string,
  method: methodType,
  body?: TBody
): Promise<IRequestData<TResponse>> {
  const header = await APP_MULTIPART_HEADER()
  return await axios({
    method: method,
    url: BASE_URL + endPoint,
    data: body,
    headers: header,
    timeout: 150000
  })
    .then(function (response: any) {
      if (successStatusCode.includes(response?.status)) return response?.data
    })
    .catch((error: any) => {
      catchErrors(error)
    })
}
export async function get_auth_url(profile: profileType) {
  return request<{}, string>(EndPoints.auth_url(profile), GET)
}

export async function logout() {
  return request<null, null>(EndPoints.logout, POST)
}

export const plaidAuthTokenLink = async () =>
  request<{}, IPlaidTokenLinkResponse>(EndPoints.plaidAuthTokenLink, POST)

export const plaidCreateAccountDetails = async (params: IPlaidAccountDetailsBody) =>
  request<IPlaidAccountDetailsBody, IPlaidAccountDetails>(
    EndPoints.creatPlaidAccountDetails,
    POST,
    params
  )
export async function termsAndConditions() {
  return request<{}, ITermAndCondition>(EndPoints.termsAndConditions, GET)
}

export async function privacyPolicies() {
  return request<{}, IPrivacyPolicy>(EndPoints.privacyPolicies, GET)
}

export async function stripeCustomerValidation() {
  return request<{}, IStripeCustomerValidation>(EndPoints.stripeCustomerValidation, GET)
}

export async function createStripeCustomer(params) {
  return request<IStripeCustomer, unknown>(EndPoints.createStripeCustomer, POST, params)
}

export async function registerPaymentIntent(params: IStripeIntentRegistration) {
  return request<IStripeIntentRegistration, IStripeResponse>(
    EndPoints.registerPaymentIntent,
    POST,
    params
  )
}

export const attachPaymentMethod = (payment_method_id: string) => {
  return request<IAttachPaymentMethod, IStripePaymentMethod>(EndPoints.attachPaymentMethod, POST, {
    payment_method_id
  })
}

export const detachPaymentMethod = (payment_method_id: string) => {
  return request<IDetachPaymentMethod, IStripePaymentMethod>(EndPoints.detachPaymentMethod, POST, {
    payment_method_id
  })
}

export async function createSetupIntent() {
  return request<{}, IStripeSetupIntent>(EndPoints.createSetupIntent, POST)
}

export async function getStripePaymentMethods() {
  return request<{}, IStripePaymentMethodsResponse>(EndPoints.getPaymentMethods, GET)
}

export async function getStripeDefaultPaymentMethod() {
  return request<{}, IStripeDefaultPaymentMethodResponse>(EndPoints.defaultPaymentMethod, GET)
}

export async function updateStripeDefaultPaymentMethod(params: IStripeDefaultPaymentMethodPayload) {
  return request<IStripeDefaultPaymentMethodPayload, IStripeDefaultPaymentMethodResponse>(
    EndPoints.defaultPaymentMethod,
    PUT,
    params
  )
}

export async function getFrontendInfo() {
  return request<{}, IFrontendInfo>(EndPoints.frontendInfo, GET)
}

export const isPublisherAuthenticated = (payload: IIsAuthenticatedPayload) =>
  request<IIsAuthenticatedPayload, IIsAuthenticated>(
    EndPoints.isPublisherAuthenticated,
    POST,
    payload
  )

export async function getPublisherSites() {
  return request<{}, IPaginatedResponse<IPublisherSite[]>>(EndPoints.publisherSites, GET)
}
export async function createPublisherSite(payload: ICreatePublisherSite) {
  return request<ICreatePublisherSite, IPublisherSite>(EndPoints.publisherSites, POST, payload)
}
export async function getSubcriptionManagement(apiKey: string) {
  return request<{}, ISubscriptionManagement>(
    EndPoints.getOrUpdateSubcriptionManagement(apiKey),
    GET
  )
}
export async function updateSubcriptionManagement(params: ISubscriptionManagementMutation) {
  return request<ISubscriptionManagementParam, ISubscriptionManagement>(
    EndPoints.getOrUpdateSubcriptionManagement(params.apiKey),
    PATCH,
    params.param
  )
}

export async function getAccountSetup(apiKey: string) {
  return request<{}, IAccountSetup>(EndPoints.accountSetup(apiKey), GET)
}

export async function updateAccountSetup(accountSetup: Partial<IAccountSetup>, apiKey: string) {
  return request<Partial<IAccountSetup>, IAccountSetup>(
    EndPoints.accountSetup(apiKey),
    PATCH,
    accountSetup
  )
}

export type IPatchPublisherSite = Partial<IPublisherSite> & { id: number }
export async function updatePublisherSite(publisherSite: IPatchPublisherSite) {
  return requestMultipart<IPatchPublisherSite, IPublisherSite>(
    EndPoints.updatePublisherSite(publisherSite.id),
    PATCH,
    publisherSite
  )
}

export async function getArticlesViewHistory(
  apiKey: string,
  searchParam: string,
  page: number,
  from: string,
  to: string,
  pageSize: number
) {
  return request<{}, IPaginatedResponse<IArticleViewHistory[]>>(
    EndPoints.getArticlesViewHistory(apiKey, searchParam, page, from, to, pageSize),
    GET
  )
}

export async function getCustomers(
  apiKey: string,
  searchParam: string,
  page: number,
  from: string,
  to: string,
  pageSize: number
) {
  return request<{}, IPaginatedResponse<ICustomer[]>>(
    EndPoints.getCustomers(apiKey, searchParam, page, from, to, pageSize),
    GET
  )
}

export async function getCustomersPlans(
  apiKey: string,
  email: string,
  pageSize: number,
  page: number
) {
  return request<{}, IPaginatedResponse<ITransactionHistory[]>>(
    EndPoints.getCustomersPlans(apiKey, email, pageSize, page),
    GET
  )
}

export async function getDashboardInfo(
  apiKey: string,

  days: number
) {
  return request<{}, IDashboardModule>(EndPoints.getDashboardInfo(apiKey, days), GET)
}

export async function getTransactionsHistory(
  apiKey: string,
  searchParam: string,
  page: number,
  days: number
) {
  return request<{}, IPaginatedResponse<ITransactionHistory[]>>(
    EndPoints.getTrasanctionHistory(apiKey, searchParam, page, days),
    GET
  )
}
