import { FormState } from "../../AccountSetup"
import { InputComponent } from "../InputComponent"
import "./WithdrawMethod.scss"

interface WithdrawMethodProps {
  initialValues: FormState
}
const WithdrawMethod = ({ initialValues }: WithdrawMethodProps) => {
  return (
    <>
      <div style={{ width: "100%", position: "relative", marginTop: "3%", maxWidth: 894 }}>
        <div style={{ marginBottom: "3%" }}>
          <div
            style={{
              color: "black",
              fontSize: 24,
              fontFamily: "Karla",
              fontWeight: "500",
              wordWrap: "break-word"
            }}
          >
            Withdraw Method{" "}
          </div>
          <div
            style={{
              textAlign: "left",
              color: "black",
              fontSize: 14,
              fontFamily: "Karla",
              fontWeight: "700",
              wordWrap: "break-word"
            }}
          >
            The bank information will only be used to transferred the money received by the
            publisher by the WordPress sells
          </div>
        </div>
        <InputComponent
          name="bank_routing_number"
          label="Bank routing number"
          type="text"
          placeholder="Enter Bank routing number"
        />
        <InputComponent
          name="bank_account_number"
          label="Bank account number"
          type="text"
          placeholder="Enter Bank account number"
        />
      </div>
    </>
  )
}

export default WithdrawMethod
