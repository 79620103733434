import { ITransactionHistory } from "../../../../../../utils/types/userPlan"
import { getDate } from "../../../../CustomerModule/components/CustomerTable/utils"
import "./TransactionDetails.scss"
interface ITrasactionDetails {
  plan: ITransactionHistory
}
export const TrasactionDetails = ({ plan }: ITrasactionDetails) => {
  return (
    <>
      <div className="nbbl-purchase-pases-modal-info">
        <div style={{ textAlign: "center", padding: "0 3%", width: "15%" }}>
          <span
            className="nbbl-purchase-pases-span"
            style={{
              fontFamily: "Karla"
            }}
          >
            {getDate(plan.created_on ?? "", "month")}
            <br />
          </span>
          <span
            className="nbbl-purchase-pases-span"
            style={{
              fontSize: 24
            }}
          >
            {getDate(plan.created_on ?? "", "day")}
            <br />
          </span>
          <span
            className="nbbl-purchase-pases-span"
            style={{
              fontSize: 16
            }}
          >
            {getDate(plan.created_on ?? "", "year")}
          </span>
        </div>

        <div style={{ width: "55%", margin: "0px 5%" }}>
          <div>
            <a
              className="nbbl-purchase-pases-span"
              style={{
                fontSize: 16,
                textDecoration: "none"
              }}
            >
              {plan.title}
            </a>
          </div>
          <div
            className="nbbl-purchase-pases-span"
            style={{
              color: "#A1A1A1",
              fontSize: 16,
              fontWeight: "500",
              wordWrap: "break-word"
            }}
          >
            {plan.email}
          </div>
        </div>
        <div className="nbbl-purchase-pases-price" style={{ width: "20%" }}>
          {parseInt(plan.amount.toString())} NBBL
        </div>
      </div>
    </>
  )
}
