import "./DaysButtons.scss"

export const DaysButtons = ({ setDays, days, value }) => {
  return (
    <>
      <div
        className={`nbbl-dashboard-button ${days === value ? "selected" : ""}`}
        onClick={() => setDays(value)}
      >
        <div
          className="nbbl-card-text"
          style={{
            fontSize: 14
          }}
        >
          Last {value} days
        </div>
      </div>
    </>
  )
}
