import React, { CSSProperties, FC } from "react"
import "./Checkbox.scss"

interface ICheckbox {
  checked: boolean
  size: number
  backgroundColor: string
  accentColor: string
  containerStyle?: CSSProperties
}
const Checkbox: FC<ICheckbox> = ({
  checked,
  size,
  backgroundColor,
  accentColor,
  containerStyle
}) => {
  return (
    <div
      className="outer"
      style={{ width: size, height: size, backgroundColor: backgroundColor, ...containerStyle }}
    >
      {checked && (
        <div
          className="inner"
          style={{ width: (size * 2) / 3, height: (size * 2) / 3, backgroundColor: accentColor }}
        />
      )}
    </div>
  )
}

export default Checkbox
