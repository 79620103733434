import { useEffect, useState } from "react"
import { useGetTransactionHistory } from "../../../../../api/queries"
import "./TransactionHistory.scss"
import TrasactionDetails from "./TransactionDetails"
import PaginationComponent from "../../../CustomerModule/components/ArticlesTable/components/PaginationComponent"

const TransactionHistory = ({ selectedSite, days }) => {
  const [searchParam, setSearchParam] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { transactionsHistory, refetchTransactionsHistory } = useGetTransactionHistory(
    selectedSite?.api_key ?? "",
    searchParam,
    currentPage,
    days
  )

  const handlerPaginationModal = (page) => {
    setCurrentPage(page)
  }
  return (
    <>
      <input
        type="text"
        name="search-input"
        placeholder="Search Transactions"
        className="nbbl-customer-search"
        onChange={(e) => {
          const value = e.target.value
          setSearchParam(value)
          refetchTransactionsHistory()
        }}
      />
      <div style={{ marginTop: "3%", width: "100%" }}>
        <div style={{ position: "relative", marginBottom: "1.5%" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                color: "#A1A1A1",
                fontSize: 16,
                fontFamily: "Karla",
                fontWeight: "700",
                wordWrap: "break-word",
                textAlign: "center",
                width: "15%",
                padding: "0px 3%"
              }}
            >
              Date
            </div>
            <div
              style={{
                color: "#A1A1A1",
                fontSize: 16,
                fontFamily: "Karla",
                fontWeight: "700",
                wordWrap: "break-word",
                width: "55%",
                margin: "0px 5%"
              }}
            >
              Description
            </div>
            <div className="nbbl-purchase-pases-price" style={{ width: "20%" }}>
              Amount
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 0,
              border: "0.50px #E4E4E4 solid"
            }}
          ></div>
        </div>
        {transactionsHistory?.map((plan) => (
          <TrasactionDetails plan={plan} />
        ))}
        <PaginationComponent
          rowsPerPage={10}
          rowCount={transactionsHistory?.length}
          currentPage={currentPage}
          onChangePage={handlerPaginationModal}
        ></PaginationComponent>
      </div>
    </>
  )
}

export default TransactionHistory
