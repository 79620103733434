import Form from "react-bootstrap/esm/Form"
import "./SelectSubscription.scss"
import { ErrorMessage, useField } from "formik"

interface SelectSubscriptionProps {
  name: string
}

export const SelectSubscription = (props: SelectSubscriptionProps) => {
  const { name } = props
  const [field, meta, helpers] = useField(name)
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    helpers.setValue(value)
  }
  const options = ["LOW", "MID", "HIGH", "CUSTOM"]
  return (
    <div style={{ width: "100%" }}>
      <div className="nbbl-select-component-container">
        <div className="nbbl-select-label">Final price</div>
        <Form.Select aria-label="select site" onChange={handleChange}>
          {options?.map((opt) => (
            <option key={opt} value={opt} selected={opt === field.value}>
              {opt}
            </option>
          ))}
          <option key={""} value={""} selected={"" === field.value}>
            ...
          </option>
        </Form.Select>
      </div>
      <ErrorMessage name={name} component="div" className="nbbl-error-message" />
    </div>
  )
}
