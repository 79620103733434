export const GeneratePages = ({ totalPages, handlePageChange, currentPage }) => {
  const pages: any = []
  const maxVisiblePages = 5
  const ellipsisThreshold = currentPage < totalPages - 2 ? currentPage + 1 : totalPages - 2

  if (totalPages <= maxVisiblePages) {
    // If there are less than or equal to 5 pages, show all pages
    for (let page = 1; page <= totalPages; page++) {
      pages.push(
        <div
          key={page}
          className={`pagination-item${page === currentPage ? " active" : ""} paginator`}
          onClick={() => handlePageChange(page)}
        >
          <div>{page}</div>
        </div>
      )
    }
  } else {
    // If there are more than 5 pages, show the initial pages, the ellipsis and the final pages
    const startPages: any = []
    const endPages: any = []

    for (
      let page = currentPage > totalPages - 3 ? totalPages - 3 : currentPage;
      page <= ellipsisThreshold;
      page++
    ) {
      startPages.push(
        <div
          key={page}
          className={`pagination-item${page === currentPage ? " active" : ""} paginator`}
          onClick={() => handlePageChange(page)}
        >
          <div>{page}</div>
        </div>
      )
    }

    for (let page = totalPages - 1; page <= totalPages; page++) {
      endPages.push(
        <div
          key={page}
          className={`pagination-item${page === currentPage ? " active" : ""} paginator`}
          onClick={() => handlePageChange(page)}
        >
          <div>{page}</div>
        </div>
      )
    }

    const ellipsis = (
      <div key="ellipsis" className="pagination-item paginator">
        <div>...</div>
      </div>
    )

    if (currentPage > totalPages - 3) {
      pages.push(...startPages, ...endPages)
    } else {
      pages.push(...startPages, ellipsis, ...endPages)
    }
  }

  return pages.map((page) => page)
}
