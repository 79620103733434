import React, { FC } from "react"
import { Modal as BootstrapModal } from "react-bootstrap"
import Button from "react-bootstrap/Button"
interface IModal {
  show: boolean
  handleClose: () => void
  onAccept: () => void
  onCancel: () => void
  title: string
  body: string
  acceptLabel: string
  cancelLabel: string
}
const Modal: FC<IModal> = (props) => {
  const { show, handleClose, onAccept, onCancel, title, body, acceptLabel, cancelLabel } = props
  return (
    <BootstrapModal show={show} onHide={handleClose}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{body}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button
          className="rounded-pill font-bold-14 text-white btn"
          variant="secondary"
          onClick={() => {
            onCancel()
            handleClose()
          }}
        >
          {cancelLabel}
        </Button>
        <Button
          className="rounded-pill font-bold-14 text-white btn"
          variant="primary"
          onClick={() => {
            onAccept()
            handleClose()
          }}
        >
          {acceptLabel}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  )
}

export default Modal
