import { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import PlaidContainer from "../PlaidContainer"
import { stripeCustomerValidation, createStripeCustomer } from "../../api/request"
import { useNavigate } from "react-router-dom"

import "./styles.scss"
import { APPCONFIG } from "../../app-config"
import { toast } from "react-hot-toast"

export default function StripeLink() {
  const nav = useNavigate()
  const connectionTriggered = useRef<boolean>(false)

  const widgetData: string = localStorage.getItem(APPCONFIG.localVars.widgetData) ?? ""
  const widgetDataJsonObject = JSON.parse(widgetData)

  const email = widgetDataJsonObject.email

  useEffect(() => {
    const token = localStorage.getItem(APPCONFIG.localVars.token)
    if (!!widgetDataJsonObject && !token) {
      window.location.href = widgetDataJsonObject.redirectURL
    }
  }, [widgetDataJsonObject])

  useEffect(() => {
    if (!email) return
    if (connectionTriggered.current) return
    connectionTriggered.current = true

    const connectToStripe = () => {
      createStripeCustomer({
        full_name: "",
        email
      }).then(
        () => {
          nav(`/${APPCONFIG.routes.stripePaymentMethod}`)
        },
        () => {
          toast.error("Error creating Stripe customer")
        }
      )
    }

    const getStripeCustomer = async () => {
      const stripeCustomer = (await stripeCustomerValidation()).payload.success
      if (stripeCustomer) {
        // setExistentCustomer(!!stripeCustomer)
        nav(`/${APPCONFIG.routes.stripePaymentMethod}`)
      } else connectToStripe()
    }

    getStripeCustomer()
  }, [email])

  return (
    <PlaidContainer>
      <Container className="px-95 text-center mt-5">
        <div>
          <p className="font-medium-24">Creating stripe customer... You'll be redirected shortly</p>
        </div>
      </Container>
    </PlaidContainer>
  )
}
