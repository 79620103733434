import { APPCONFIG } from "../app-config"
var Buffer = require("buffer/").Buffer

interface IWidgetDataJsonObject {
  redirectURL: string
  email?: string
}

// URL-safe Base64 encoding
export function urlSafeBase64Encode(str: string) {
  var base64 = Buffer.toString("base64")
  return base64.replace(/\+/g, "-").replace(/\//g, "_")
}

// URL-safe Base64 decoding
export function urlSafeBase64Decode(encodedStr: string) {
  var base64 = encodedStr.replace(/-/g, "+").replace(/_/g, "/")
  return Buffer.from(base64, "base64").toString("ascii")
}

export const useWidgetData = (): { widgetDataJsonObject: IWidgetDataJsonObject } => {
  const widgetData: string = localStorage.getItem(APPCONFIG.localVars.widgetData) ?? ""
  const widgetDataJsonObject: IWidgetDataJsonObject = JSON.parse(widgetData)
  return { widgetDataJsonObject }
}
