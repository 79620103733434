import * as yup from "yup"
import english from "./english"

export const registrationSchema = yup.object().shape({
  email: yup.string().email(),
  phone_number: yup.string().when("email", (email) => {
    return email.toString() === "" ? yup.string().required() : yup.string()
  })
})

export const accountSettupSchema = yup.object().shape({
  bank_routing_number: yup.string().required("Bank routing number is a required field"),
  bank_account_number: yup.string().required("Bank account number is a required field"),
  site_url: yup
    .string()
    .matches(
      /^(?:(?:https?:)?\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/.*)?$/,
      "Invalid URL"
    )
    .required("Site url  is a required field"),
  site_name: yup.string().required("Site name is a required field")
})

export const subscriptionsValidationsSchema = yup.object().shape({
  single_article_low: yup
    .number()
    .min(1, "The number must be greater than or equal to one")
    .required("This field is required"),
  single_article_mid: yup
    .number()
    .min(1, "The number must be greater than or equal to one")
    .required("This field is required"),
  single_article_high: yup
    .number()
    .min(1, "The number must be greater than or equal to one")
    .required("This field is required"),
  single_article_custom: yup
    .number()
    .min(1, "The number must be greater than or equal to one")
    .required("This field is required"),
  day_pass: yup
    .number()
    .test("price-validation", "The number must be greater than or equal to one", function (value) {
      const isActive = this.resolve(yup.ref("day_pass_is_active"))
      if (!isActive) return true // Permitir cualquier número si está deshabilitado
      return value !== undefined && value >= 1
    }),
  week_pass: yup
    .number()
    .test("price-validation", "The number must be greater than or equal to one", function (value) {
      const isActive = this.resolve(yup.ref("week_pass_is_active"))
      if (!isActive) return true // Permitir cualquier número si está deshabilitado
      return value !== undefined && value >= 1
    }),
  month_pass: yup
    .number()
    .test("price-validation", "The number must be greater than or equal to one", function (value) {
      const isActive = this.resolve(yup.ref("month_pass_is_active"))
      if (!isActive) return true // Permitir cualquier número si está deshabilitado
      return value !== undefined && value >= 1
    }),
  year_pass: yup
    .number()
    .test("price-validation", "The number must be greater than or equal to one", function (value) {
      const isActive = this.resolve(yup.ref("year_pass_is_active"))
      if (!isActive) return true // Permitir cualquier número si está deshabilitado
      return value !== undefined && value >= 1
    }),
  ts_price_1: yup.number().min(1, "This field is required").required(),
  duration_ts_1: yup.number().min(1, "This field is required").required(),
  final_price_ts_1: yup.string().required("This field is required"),
  ts_price_2: yup.number().min(1, "This field is required").required(),
  duration_ts_2: yup.number().min(1, "This field is required").required(),
  final_price_ts_2: yup.string().required("This field is required"),
  ts_price_custom: yup.number().min(1, "This field is required").required(),
  duration_ts_custom: yup.number().min(1, "This field is required").required(),
  final_price_ts_custom: yup.string().required("This field is required")
})
