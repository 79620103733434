import { useEffect, useState } from "react"
import { useGetCustomer, useGetCustomerPlans } from "../../../../../api/queries"

import DataTable from "react-data-table-component"
import ActionsTable from "../ActionsTable"
import { customStyles, formatDate, getDate } from "./utils"
import PaginationComponent from "../ArticlesTable/components/PaginationComponent"
import "./CustomerTable.scss"
import { Modal } from "react-bootstrap"
import { getCustomers } from "../../../../../api/request"
import { downloadCSV } from "../../utils"
import { APPCONFIG } from "../../../../../app-config"
import { IViewsProps } from "../../../Drawer/utils"
import { ICustomer } from "../../../../../utils/types/accountSetup"
import { toast } from "react-hot-toast"

const CustomerTable = ({ selectedSite }: IViewsProps) => {
  const [lgShow, setLgShow] = useState<boolean>(false)
  const [searchParam, setSearchParam] = useState<string>("")
  const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [startDate, endDate] = dateRange
  const [apiKey, setApiKey] = useState<string>("")
  const { customerList, refetchCustomerList } = useGetCustomer(
    apiKey,
    searchParam,
    currentPage,
    startDate,
    endDate,
    10
  )
  const [customerEmail, setCustomerEmail] = useState<string>("")
  const [currentPagePlan, setCurrentPagePlan] = useState<number>(1)
  const { customerPlans, refetchCustomerPlans } = useGetCustomerPlans(
    apiKey,
    customerEmail,
    5,
    currentPagePlan
  )
  const [data, setData] = useState<ICustomer[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  useEffect(() => {
    if (!customerList) return
    setTotalPages(customerList.count)
    setData(customerList.results)
  }, [customerList])
  useEffect(() => {
    if (!selectedSite) return
    setApiKey(selectedSite.api_key)
  }, [selectedSite])
  const handlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchParam(value)
    refetchCustomerList()
  }
  const handlerDateSet = (update: Date[]) => {
    const from = new Date(update[0])
    const to = new Date(update[1])
    setDateRange(update)
    if (from < to) {
      refetchCustomerList()
    }
  }
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "12.5%",
      sortable: true
    },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      width: "12.5%",
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "15%",
      sortable: true
    },
    {
      name: "$ Invested (usd)",
      selector: (row) => "$ " + row.invested,
      width: "15%",
      sortable: true
    },
    {
      name: "Purchase history",
      selector: (row) => (
        <div
          className="nbbl-cell-purchase-pases"
          onClick={() => {
            setCustomerEmail(row.email)
            refetchCustomerPlans()
            setLgShow(true)
          }}
        >
          {row.purchased_plan}
        </div>
      ),
      width: "15%",
      sortable: true
    },
    {
      name: "Registration date",
      selector: (row) => formatDate(row.registration_date),
      width: "15%",
      sortable: true
    },
    {
      name: "Last expires pass at",
      selector: (row) => formatDate(row.last_date),
      width: "15%",
      sortable: true
    }
  ]
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const handerExport = () => {
    getCustomers(apiKey, "", 1, "", "", totalPages).then(
      (resp) => {
        const data = resp.payload.results
        downloadCSV(data)
      },
      (reason) => {
        toast.error(reason)
      }
    )
  }

  const handlerPaginationModal = (page) => {
    setCurrentPagePlan(page)
    refetchCustomerPlans()
  }

  const conditionalRowStyles = [
    {
      when: (row) => {
        const rowIndex = data.findIndex((item) => item === row)
        return rowIndex % 2 === 0
      },
      style: {
        backgroundColor: "#FCFCFC",
        textAlign: "center"
      }
    },
    {
      when: (row) => {
        const rowIndex = data.findIndex((item) => item === row)
        return rowIndex % 2 !== 0
      },
      style: {
        backgroundColor: "#F3F8FE",
        textAlign: "center"
      }
    }
  ]
  return (
    <>
      <ActionsTable
        handlerSearch={handlerSearch}
        handlerDateSet={handlerDateSet}
        startDate={startDate}
        endDate={endDate}
        handerExport={handerExport}
      />
      <div className="nbbl-table-cont">
        <DataTable
          data={data}
          columns={columns}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          paginationServer
          paginationPerPage={10}
          paginationTotalRows={totalPages}
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationComponent={PaginationComponent}
          onChangePage={handlePageChange}
        />
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ textAlign: "center", width: "100%" }}
          >
            Purchase history
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="nbbl-modal-body">
          {customerPlans?.results.map((plan) => (
            <div className="nbbl-purchase-pases-modal-info">
              <div style={{ textAlign: "center", padding: "0 3%" }}>
                <span
                  style={{
                    color: "#272727",
                    fontSize: 16,
                    fontFamily: "Karla",
                    fontWeight: "700",
                    wordWrap: "break-word"
                  }}
                >
                  {getDate(plan?.created_on ?? "", "month")}
                  <br />
                </span>
                <span
                  style={{
                    color: "#272727",
                    fontSize: 24,
                    fontFamily: "Karla",
                    fontWeight: "700",
                    wordWrap: "break-word"
                  }}
                >
                  {getDate(plan?.created_on ?? "", "day")}
                  <br />
                </span>
                <span
                  style={{
                    color: "#272727",
                    fontSize: 16,
                    fontFamily: "Karla",
                    fontWeight: "700",
                    wordWrap: "break-word"
                  }}
                >
                  {getDate(plan?.created_on ?? "", "year")}
                </span>
              </div>

              <div style={{ width: "55%" }}>
                <div>
                  <a
                    target="_blank"
                    style={{
                      color: "#272727",
                      fontSize: 16,
                      fontFamily: "Karla",
                      fontWeight: "700",
                      wordWrap: "break-word"
                    }}
                  >
                    {plan.title}
                  </a>
                </div>
                <div
                  style={{
                    color: "#A1A1A1",
                    fontSize: 16,
                    fontFamily: "Karla",
                    fontWeight: "500",
                    wordWrap: "break-word"
                  }}
                >
                  {customerEmail}
                </div>
              </div>
              <div className="nbbl-purchase-pases-price">{plan?.amount} NBBL</div>
            </div>
          ))}
          <PaginationComponent
            rowsPerPage={5}
            rowCount={customerPlans?.count}
            currentPage={currentPagePlan}
            onChangePage={handlerPaginationModal}
          ></PaginationComponent>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomerTable
