import React, { useEffect, useRef } from "react"
import { APPCONFIG } from "../../app-config"
import { useSearchParams } from "react-router-dom"
import { useWidgetData } from "../../utils/common"

export default function BackToArticle() {
  const { widgetDataJsonObject } = useWidgetData()

  const [queryParameters] = useSearchParams()
  const url_access_token = queryParameters.get("access_token")
  let access_token = localStorage.getItem(APPCONFIG.localVars.token)
  if (!!url_access_token) {
    access_token = url_access_token
    localStorage.setItem(APPCONFIG.localVars.token, url_access_token)
  }

  const redirectFrom = useRef<HTMLFormElement>(null)

  useEffect(() => {
    redirectFrom?.current?.submit()
  }, [])

  return (
    <div>
      <form method="GET" action={widgetDataJsonObject.redirectURL} ref={redirectFrom}>
        {access_token && <input type="hidden" name="access_token" value={access_token} />}
      </form>
    </div>
  )
}
