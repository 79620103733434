import React, { useState } from "react"
import { IViewsProps } from "../Drawer/utils"

import "./CustomerModule.scss"
import ArticlesTable from "./components/ArticlesTable/ArticlesTable"
import CustomerTable from "./components/CustomerTable/CustomerTable"
import { ButtonAccount } from "../AccountSetup/components/ButtonAccount"

const customerRoutes = {
  articlesTable: "articles-table",
  customerTable: "customer-table"
}

function CustomerModule({ selectedSite }: IViewsProps) {
  const customerViews = [
    {
      title: customerRoutes.articlesTable,
      Content: ({ selectedSite }: IViewsProps) => <ArticlesTable selectedSite={selectedSite} />
    },
    {
      title: customerRoutes.customerTable,
      Content: ({ selectedSite }: IViewsProps) => <CustomerTable selectedSite={selectedSite} />
    }
  ]
  const [activeComponent, setactiveComponent] = useState<string>(customerRoutes.customerTable)

  const [ContentComponent, setContentComponent] = useState<
    (({ selectedSite }: IViewsProps) => JSX.Element) | undefined
  >(() => customerViews.find((item) => item.title === activeComponent)?.Content)

  const activeComponentHandler = (comp: string) => {
    setactiveComponent(comp)
    setContentComponent(() => customerViews.find((item) => item.title === comp)?.Content)
  }
  return (
    <div style={{ width: "100%", padding: "3%" }}>
      <div className="nbbl-customer-module-header">
        <div className="nbbl-customer-module-title">Customers and Articles</div>
        <div className="nbbl-customer-module-subtitle">
          At this section, you will be able to check the NBBL Customers and Articles
        </div>
      </div>
      <div className="nbbl-customer-module-nav">
        <ButtonAccount
          text="NBBL Customers"
          onClick={() => {
            activeComponentHandler(customerRoutes.customerTable)
          }}
          selected={customerRoutes.customerTable === activeComponent}
        />
        <ButtonAccount
          text="NBBL Articles"
          onClick={() => {
            activeComponentHandler(customerRoutes.articlesTable)
          }}
          selected={customerRoutes.articlesTable === activeComponent}
        />
      </div>
      {ContentComponent && <ContentComponent selectedSite={selectedSite} />}
    </div>
  )
}

export default CustomerModule
