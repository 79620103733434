import Form from "react-bootstrap/Form"
import "./SelectCustom.scss"
import { IPublisherSite } from "../../../../../utils/types/publisherSite"
import { useEffect } from "react"

const newSite = "new site"
interface SelectCustomProps {
  options: IPublisherSite[]
  setSelectedSite: React.Dispatch<React.SetStateAction<IPublisherSite | undefined>>
  addNewSite: React.Dispatch<React.SetStateAction<boolean>>
  selectedSite: IPublisherSite | undefined
}

const SelectCustom = ({
  options = [],
  setSelectedSite,
  addNewSite,
  selectedSite
}: SelectCustomProps) => {
  return (
    <Form.Select
      aria-label="select site"
      onChange={(e) => {
        const apiKey = e.target.value
        const publisher = options.find((pub) => pub.api_key === apiKey)
        publisher && setSelectedSite(publisher)
        apiKey === newSite && addNewSite(true)
      }}
    >
      {options?.map((publisher) => (
        <option
          key={publisher.api_key}
          value={publisher.api_key}
          selected={selectedSite?.api_key === publisher.api_key}
        >
          {publisher.name}
        </option>
      ))}
      <option key={newSite} value={newSite}>
        Add new site
      </option>
    </Form.Select>
  )
}

export default SelectCustom
