import { useField, ErrorMessage, useFormikContext } from "formik"
import "./InputComponent.scss"
import svg from "../../../../../assets/images/svg"
import { useState } from "react"

interface InputComponentProps {
  label: string
  type: string
  readOnly?: boolean
  placeholder: string
  name: string
  showCopyIcon?: boolean
}

const InputComponent = ({
  label,
  type,
  readOnly = false,
  placeholder,
  name,
  showCopyIcon = false
}: InputComponentProps) => {
  const [field, meta, helpers] = useField(name)
  const { setFieldValue } = useFormikContext()
  const [copied, setCopied] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    helpers.setValue(value)
  }
  const handlerBlur = () => {
    if (name === "site_url") {
      if (!field.value.trim().includes("http://") && !field.value.trim().includes("https://")) {
        setFieldValue(name, `https://${field.value}`, true)
      }
    }
  }
  const handleCopy = () => {
    navigator.clipboard
      .writeText(field.value)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 1500)
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles:", error)
      })
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="nbbl-input-component-container">
        <div className="nbbl-input-label">{label}</div>
        <input
          {...field}
          type={type}
          className="nbbl-input-component"
          readOnly={readOnly}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          onBlur={handlerBlur}
        />
        {readOnly && showCopyIcon ? (
          !copied ? (
            <img
              src={svg.CopyRegular}
              className="nbbl-icon-copy"
              onClick={handleCopy}
              alt="icon-copy"
            />
          ) : (
            <p style={{ margin: "0 10px" }}>Copied!</p>
          )
        ) : null}
      </div>
      <ErrorMessage name={name} component="div" className="nbbl-error-message" />
    </div>
  )
}

export default InputComponent
