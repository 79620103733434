import React, { useEffect, useState } from "react"
import "./AccountSetup.scss"
import { ButtonAccount } from "./components/ButtonAccount"
import { WithdrawMethod } from "./components/WithdrawMethod"
import { WordpressIntegration } from "./components/WordpressIntegration"
import { SMTPConfiguration } from "./components/SMTPConfiguration"
import { AccountConfiguration } from "./components/AccountConfiguration"
import { Form, Formik } from "formik"
import { IViewsProps } from "../Drawer/utils"
import { useAccountSetupQuery, useGetPublisherSites } from "../../../api/queries"
import { useAccountSetupMutation, usePublisherSiteMutation } from "../../../api/mutations"
import { useQueryClient } from "react-query"
import { APPCONFIG } from "../../../app-config"
import { useNavigate } from "react-router-dom"
import { accountSettupSchema } from "../../../utils/validations"
import ModalPublisher from "../ModalPublisher/ModalPublisher"
import { IAccountSetup } from "../../../utils/types/accountSetup"
import { toast } from "react-hot-toast"
import video from "../../../assets/video"
const FORMROUTES = {
  withdrawMethod: "withdraw-method",
  wordpressIntegration: "wordpress-integration",
  SMTPConfiguration: "SMTP-configuration",
  accountConfiguration: "Account configuration"
}
export interface FormState {
  bank_routing_number: string
  bank_account_number: string
  api_key: string
  SMTP_email: string
  SMTP_username: string
  SMTP_password: string
  SMTP_port_number: number
  SMTP_host: string
  publisher_email: string
  site_url: string
  site_name: string
  logo: any
}

export interface FormViewsProps {
  initialValues: FormState
  logo: string | undefined
}

const AccountSetup = ({
  selectedSite,
  setHasChanges,
  hasChanges,
  setSelectedSite
}: IViewsProps) => {
  const nav = useNavigate()
  const queryClient = useQueryClient()

  const [showModalPublisher, setShowModalPublisher] = useState<boolean>(false)
  const { accountSetup } = useAccountSetupQuery(selectedSite?.api_key ?? "")
  const { publisherSites } = useGetPublisherSites()
  const accountSetupMutation = useAccountSetupMutation()
  const publisherSiteMutation = usePublisherSiteMutation()

  const [formState, setFormState] = useState<FormState>({
    bank_routing_number: "",
    bank_account_number: "",
    api_key: "",
    SMTP_email: "",
    SMTP_username: "",
    SMTP_password: "",
    SMTP_port_number: 0,
    SMTP_host: "",
    publisher_email: "",
    site_url: "",
    site_name: "",
    logo: ""
  })

  useEffect(() => {
    if (!accountSetup) return
    const {
      publisher_site: { name, url },
      bank_account_number,
      bank_routing_number
    } = accountSetup
    if (!name || !url || !bank_account_number || !bank_routing_number) {
      nav(`/${APPCONFIG.routes.publisher.registration}/${selectedSite?.api_key}`)
    }

    const formStateCopy: FormState = {
      api_key: selectedSite?.api_key ?? "",
      site_name: selectedSite?.name ?? "",
      site_url: selectedSite?.url ?? "",
      logo: selectedSite?.logo,
      bank_account_number: accountSetup.bank_account_number,
      bank_routing_number: accountSetup.bank_routing_number,
      SMTP_email: accountSetup.smtp_email,
      SMTP_host: accountSetup.smtp_host,
      SMTP_password: accountSetup.smtp_email_password,
      SMTP_port_number: accountSetup.smpt_port,
      SMTP_username: accountSetup.smtp_username,
      publisher_email: accountSetup.publisher_email
    }
    setFormState(formStateCopy)
  }, [accountSetup, selectedSite])

  const formViews = [
    {
      title: FORMROUTES.withdrawMethod,
      Content: ({ initialValues }: FormViewsProps) => (
        <WithdrawMethod initialValues={initialValues} />
      )
    },
    {
      title: FORMROUTES.wordpressIntegration,
      Content: ({ initialValues }: FormViewsProps) => (
        <WordpressIntegration initialValues={initialValues} />
      )
    },
    {
      title: FORMROUTES.SMTPConfiguration,
      Content: ({ initialValues }: FormViewsProps) => (
        <SMTPConfiguration initialValues={initialValues} />
      )
    },
    {
      title: FORMROUTES.accountConfiguration,
      Content: ({ initialValues, logo }: FormViewsProps) => (
        <AccountConfiguration initialValues={initialValues} logo={logo} />
      )
    }
  ]

  const [activeComponent, setactiveComponent] = useState<string>(FORMROUTES.withdrawMethod)

  const [ContentComponent, setContentComponent] = useState<
    (({ initialValues, logo }: FormViewsProps) => JSX.Element) | undefined
  >(() => formViews.find((item) => item.title === activeComponent)?.Content)

  const activeComponentHandler = (comp: string) => {
    setactiveComponent(comp)
    setContentComponent(() => formViews.find((item) => item.title === comp)?.Content)
  }

  const handlerSubmit = (form: FormState, resetForm) => {
    if (!accountSetup) return
    const updatedAccountSetup: IAccountSetup = {
      ...accountSetup,
      smtp_email: form.SMTP_email,
      smtp_host: form.SMTP_host,
      smtp_email_password: form.SMTP_password,
      smpt_port: form.SMTP_port_number,
      smtp_username: form.SMTP_username,
      publisher_email: form.publisher_email,
      bank_routing_number: form.bank_routing_number,
      bank_account_number: form.bank_account_number,
      publisher_site: {
        id: accountSetup.publisher_site.id,
        publisher: accountSetup.publisher_site.publisher,
        api_key: accountSetup.publisher_site.api_key,
        name: form.site_name,
        url: form.site_url,
        logo: form.logo
      }
    }
    if (!(form.logo instanceof File)) {
      delete updatedAccountSetup.publisher_site.logo
    }
    publisherSiteMutation.mutate(updatedAccountSetup.publisher_site, {
      onSuccess: () => {
        delete updatedAccountSetup.publisher_site.logo
        accountSetupMutation.mutate(
          { accountSetup: updatedAccountSetup, apiKey: accountSetup.publisher_site.api_key },
          {
            onSuccess: () => {
              toast.success("Changes have been saved")
              queryClient.setQueryData(["accountSetup"], updatedAccountSetup)
              const publisherSitesAux = publisherSites?.filter(
                (site) => site.api_key !== updatedAccountSetup.publisher_site.api_key
              )
              publisherSitesAux?.push(updatedAccountSetup.publisher_site)
              queryClient.setQueryData(["publisher_sites"], publisherSitesAux)
              setHasChanges && setHasChanges(false)
              resetForm({ values: form })
              // TODO: redirect to publisher app
            },
            onError: () => {
              toast.error("Error saving account setup")
            }
          }
        )
      },
      onError: () => {
        toast.error("Erorr saving publisher site")
      }
    })
  }
  const handlerOk = () => {
    window.location.reload()
  }
  return (
    <>
      <div style={{ width: "49.042%", padding: "3% 0 0 3%", marginRight: "4%  " }}>
        <div className="nbbl-title">
          <div className="nbbl-primary">Account Setup</div>
          <div className="nbbl-secondary">
            <div className="nbbl-secondary-text">Setup your NBBL account</div>
          </div>
        </div>
        <div className="nbbl-account-setup-nav-form">
          <ButtonAccount
            text="Withdraw Method"
            onClick={() => {
              activeComponentHandler(FORMROUTES.withdrawMethod)
            }}
            selected={FORMROUTES.withdrawMethod === activeComponent}
          />
          <ButtonAccount
            text="WordPress Integration"
            onClick={() => {
              activeComponentHandler(FORMROUTES.wordpressIntegration)
            }}
            selected={FORMROUTES.wordpressIntegration === activeComponent}
          />
          {/* <ButtonAccount
            text="SMTP Configuration"
            onClick={() => {
              activeComponentHandler(FORMROUTES.SMTPConfiguration)
            }}
            selected={FORMROUTES.SMTPConfiguration === activeComponent}
          /> */}
          <ButtonAccount
            text="Account configuration"
            onClick={() => {
              activeComponentHandler(FORMROUTES.accountConfiguration)
            }}
            selected={FORMROUTES.accountConfiguration === activeComponent}
          />
        </div>

        <Formik
          initialValues={formState}
          enableReinitialize={true}
          validationSchema={accountSettupSchema}
          onSubmit={(values, { resetForm }) => {
            handlerSubmit(values, resetForm)
          }}
        >
          {({ dirty, values }) => {
            dirty && setHasChanges && setHasChanges(dirty)
            return (
              <Form encType="multipart/form-data">
                {ContentComponent && (
                  <ContentComponent initialValues={formState} logo={selectedSite?.logo} />
                )}
                <div
                  className="nbbl-account-form-actions"
                  style={{ justifyContent: `${hasChanges ? "space-between" : "flex-end"}` }}
                >
                  {hasChanges && (
                    <div
                      className="nbbl-account-form-buttons cancel"
                      onClick={() => {
                        setShowModalPublisher(true)
                      }}
                    >
                      <div className="nbbl-account-form-button-text">Cancel</div>
                    </div>
                  )}
                  <button className="nbbl-account-form-buttons save" type="submit">
                    <div className="nbbl-account-form-button-text">Save changes</div>
                  </button>
                </div>
                {FORMROUTES.accountConfiguration === activeComponent && (
                  <div style={{ marginTop: "10%" }}>
                    <a
                      className="nbbl-account-setup-mail-to"
                      href={`mailto:support@hamiltons.cash?subject=I%20want%20to%20delete%20my%20Nbbl%20site%20${selectedSite?.name}&body=Hi%20Hamilton%20team,%20I%20want%20to%20delete%20my%20${selectedSite?.url}%20site,%20please%20let%20me%20know%20how%20to%20continue%0D%0ABest%20regards`}
                    >
                      <div className="nbbl-account-setup-delete">Delete site</div>
                    </a>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
      <div
        style={{
          width: "28%",
          height: "50%",
          padding: "3% 3% 0 0",
          overflow: "hidden",
          position: "relative",
          maxWidth: 542
        }}
      >
        <div className="nbbl-account-help">
          <div className="nbbl-account-help-text">
            How to implement NBBLs plugin into your WordPress{" "}
          </div>
          <video width={"80%"} height={"50%"} controls>
            <source src={video.tutorial} type="video/mp4" />
            Tu navegador no soporta la reproducción de videos.
          </video>
         
        </div>
      </div>
      <ModalPublisher
        title="Account Setup"
        text="You will lose your uncommitted changes if you leave this page. Are you sure?"
        show={showModalPublisher}
        handlerCancel={() => setShowModalPublisher(false)}
        handlerOk={() => handlerOk()}
      />
    </>
  )
}

export default AccountSetup
