import { useEffect, useState } from "react"
import { useGetArticlesViewHistory } from "../../../../../api/queries"
import DatePicker from "react-datepicker"
import DataTable from "react-data-table-component"
import PaginationComponent from "./components/PaginationComponent"

import "react-datepicker/dist/react-datepicker.css"
import "./ArticlesTable.scss"

import { downloadCSV } from "../../utils"
import { getArticlesViewHistory } from "../../../../../api/request"
import { IViewsProps } from "../../../Drawer/utils"
import { IArticleViewHistory } from "../../../../../utils/types/accountSetup"
import { toast } from "react-hot-toast"

const ArticlesTable = ({ selectedSite }: IViewsProps) => {
  const ITEMS_PER_PAGE = 10
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParam, setSearchParam] = useState<string>("")
  const [dateRange, setDateRange] = useState([null, null])
  const [apiKey, setApiKey] = useState<string>("")
  const [startDate, endDate] = dateRange
  const { articlesViewHistory, refetchArticlesViewHistory } = useGetArticlesViewHistory(
    apiKey,
    searchParam,
    currentPage,
    startDate,
    endDate,
    10
  )
  const [data, setData] = useState<IArticleViewHistory[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  useEffect(() => {
    if (!selectedSite) return
    setApiKey(selectedSite.api_key)
  }, [selectedSite])
  const columns = [
    {
      name: "ID",
      selector: (row) => +row.id,
      width: "10%",
      sortable: true
    },
    {
      name: "Article name",
      selector: (row) => row.name,
      width: "60%",
      sortable: true
    },
    {
      name: "Times sold",
      selector: (row) => row.sold_count,
      width: "10%",
      sortable: true
    },
    {
      name: "Revenue",
      selector: (row) => "$ " + row.revenue_sum,
      width: "10%",
      sortable: true
    },
    {
      name: "Times read",
      selector: (row) => row.view_count,
      width: "10%",
      sortable: true
    }
  ]
  useEffect(() => {
    if (!articlesViewHistory) return
    setTotalPages(articlesViewHistory.count)
    setData(articlesViewHistory.results)
  }, [articlesViewHistory])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const customStyles = {
    headCells: {
      style: {
        background: "#F5F5F5",
        color: "black",
        fontSize: 14,
        fontFamily: "Karla",
        fontWeight: "700"
      }
    },
    cells: {
      style: {
        color: "black",
        fontSize: 16,
        fontFamily: "Karla",
        fontWeight: "500",
        wordWrap: "break-word"
      }
    }
  }
  const conditionalRowStyles = [
    {
      when: (row) => row.id % 2 === 0, // Condición para filas pares
      style: {
        backgroundColor: "#FCFCFC"
      }
    },
    {
      when: (row) => row.id % 2 !== 0, // Condición para filas impares
      style: {
        backgroundColor: "#F3F8FE"
      }
    }
  ]
  const handlerExport = async () => {
    getArticlesViewHistory(apiKey, "", 1, "", "", totalPages).then(
      (resp) => {
        const data = resp.payload.results
        downloadCSV(data)
      },
      (reason) => {
        toast.error(reason)
      }
    )
  }
  return (
    <>
      <div className="nbbl-customer-table-container">
        <input
          type="text"
          name="search-input"
          placeholder="Search"
          className="nbbl-customer-search"
          onChange={(e) => {
            const value = e.target.value
            setSearchParam(value)
            refetchArticlesViewHistory()
          }}
        />
        <div className="nbbl-date-cont">
          <div className="nbbl-date-label">Filter by Date</div>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd.MM"
            onChange={(update) => {
              const from = new Date(update[0])
              const to = new Date(update[1])
              setDateRange(update)
              if (from < to) {
                refetchArticlesViewHistory()
              }
            }}
          />
        </div>
        <div className="nbbl-export-button" onClick={handlerExport}>
          <div className="nbbl-export-button-text">Export data</div>
        </div>
      </div>
      <div className="nbbl-table-cont">
        <DataTable
          data={data}
          columns={columns}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          paginationServer
          paginationPerPage={ITEMS_PER_PAGE}
          paginationTotalRows={totalPages}
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationComponent={PaginationComponent}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export default ArticlesTable
