interface UserKPIsProps {
  active_passess: number
  invested_plans: number
}

export const UserKPIs = ({ active_passess, invested_plans }: UserKPIsProps) => {
  return (
    <>
      <div style={{ position: "relative", marginTop: "2%" }}>
        <div
          className="nbbl-card-text"
          style={{
            fontSize: 24,

            fontWeight: "500"
          }}
        >
          Users KPIs
        </div>
        <div className="nbbl-section-cont">
          <div className="nbbl-card-container">
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              Active passes
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              {active_passess}
            </div>
          </div>
          <div className="nbbl-card-container">
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              Last month revenue
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              ${invested_plans}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
