import React, { FC, useState } from "react"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button, Modal } from "react-bootstrap"
import { toast } from "react-hot-toast"
import { useStripePaymentMethodsQuery } from "../../../api/queries"
import { attachPaymentMethod } from "../../../api/request"

interface INewPaymentMthodModal {
  show: boolean
  onClose: () => void
  afterSave: (methodId: string) => void
}
const NewPaymentMethodModal: FC<INewPaymentMthodModal> = ({ show, onClose, afterSave }) => {
  const stripe = useStripe()
  const elements = useElements()

  const { refetchStripePaymentMethods } = useStripePaymentMethodsQuery()
  const [paymentElementReady, setPaymentElementReady] = useState<boolean>(false)

  const handlePaymentMethodCreationError = () => {
    toast.error("An error ocurred while adding this payment method")
    onClose()
  }

  const saveMethod = async () => {
    if (!stripe || !elements) return
    await elements.submit()
    stripe
      .createPaymentMethod({
        elements
      })
      .then(
        (response) =>
          attachPaymentMethod(response.paymentMethod?.id ?? "").then(() => {
            toast.success("Payment method added")
            refetchStripePaymentMethods()
            afterSave(response.paymentMethod?.id ?? "")
            onClose()
          }, handlePaymentMethodCreationError),
        handlePaymentMethodCreationError
      )
  }
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentElement onReady={() => setPaymentElementReady(true)} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary rounded-pill" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary rounded-pill" onClick={saveMethod} disabled={!paymentElementReady}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewPaymentMethodModal
