export const Passes = ({ type, count, revenue }) => {
  return (
    <>
      <div className="nbbl-card-container" style={{ maxWidth: 279, maxHeight: 260 }}>
        <div style={{ position: "relative" }}>
          <div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              {type}
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              {count}
            </div>
          </div>
          <div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 18
              }}
            >
              Revenue
            </div>
            <div
              className="nbbl-card-text"
              style={{
                fontSize: 32
              }}
            >
              ${revenue}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
