import React, { DetailedHTMLProps, InputHTMLAttributes, useRef } from "react"
import "./styles.scss"

interface ITextInput
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
}
const TextInput: React.FC<ITextInput> = (props) => {
  const inputFieldRef = useRef<HTMLInputElement>(null)
  const { label, type, name, value, onChange, onBlur, disabled } = props
  return (
    <div className="textInput">
      <div className={value ? "input-container hasValue" : "input-container"}>
        <input
          ref={inputFieldRef}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <div className="input-label" onClick={() => inputFieldRef.current?.focus()}>
          <span>{label}</span>
        </div>
      </div>
    </div>
  )
}
export default TextInput
