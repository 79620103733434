import React, { FC, useEffect, useRef, useState } from "react"
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js"
import {
  useFrontendInfoQuery,
  useStripeDefaultPaymentMethodQuery,
  useStripePaymentMethodsQuery
} from "../../api/queries"
import { Button, Container } from "react-bootstrap"
import {
  useStripeDefualtPaymentMethodMutation,
  useStripeDetachPaymentMethodMutation
} from "../../api/mutations"
import { Elements } from "@stripe/react-stripe-js"
import NewPaymentMethodModal from "./components/NewPaymentMethodModal"
import "./StripePaymentMethod.scss"
import DeleteIcon from "../../assets/icons/deleteIcon"
import { useNavigate } from "react-router-dom"
import { APPCONFIG } from "../../app-config"

interface IStripePaymentMethod {
  showReturnToArticle?: boolean
}
const StripePaymentMethod: FC<IStripePaymentMethod> = ({ showReturnToArticle }) => {
  const nav = useNavigate()

  const { frontendInfo } = useFrontendInfoQuery()
  const { stripePaymentMethods } = useStripePaymentMethodsQuery()
  const { stripeDefaultPaymentMethod } = useStripeDefaultPaymentMethodQuery()
  const stripeDefaultPaymentMethodMutation = useStripeDefualtPaymentMethodMutation()
  const stripeDetachPaymentMethodMutation = useStripeDetachPaymentMethodMutation()

  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState<boolean>(false)
  const [disableDelete, setDisableDelete] = useState<boolean>(false)
  const [disableDefault, setDisableDefault] = useState<boolean>(false)

  useEffect(() => {
    if (!stripePaymentMethods) return
    if (stripePaymentMethods?.length) return
    setShowNewPaymentMethod(true)
  }, [stripePaymentMethods])

  useEffect(() => {
    setDisableDelete(false)
  }, [stripePaymentMethods?.length])

  useEffect(() => {
    setDisableDefault(false)
  }, [stripeDefaultPaymentMethod])

  if (!stripePaymentMethods || !frontendInfo?.stripe_public_key) {
    return (
      <Container className="px-95 text-center mt-5">
        <div>
          <p className="font-medium-24">Loading...</p>
        </div>
      </Container>
    )
  }
  return (
    <>
      <Elements
        stripe={loadStripe(frontendInfo.stripe_public_key)}
        options={{
          currency: "usd",
          mode: "setup",
          setup_future_usage: "off_session",
          paymentMethodCreation: "manual"
        }}
      >
        <NewPaymentMethodModal
          show={showNewPaymentMethod}
          onClose={() => setShowNewPaymentMethod(false)}
          afterSave={(methodId: string) => {
            stripeDefaultPaymentMethodMutation.mutate(methodId)
            nav(`/${APPCONFIG.routes.backToArticle}`)
          }}
        />
      </Elements>
      <div className="m-5">
        {!!stripePaymentMethods && (
          <Container className="font-medium-20 px-95">
            {stripePaymentMethods.map((method) => (
              <div className="payment-method-container" key={method.id}>
                <div className="payment-method-container--info">
                  <label>
                    {method.card.brand} &nbsp; &nbsp; **** **** **** {method.card.last4}
                  </label>
                  <label>
                    Expires {method.card.exp_month}\{method.card.exp_year}
                  </label>
                </div>
                {stripeDefaultPaymentMethod?.id === method.id ? (
                  <div className="default-tag">default</div>
                ) : (
                  <div className="d-flex card-actions">
                    <button
                      className="default-tag--not"
                      onClick={() => {
                        stripeDefaultPaymentMethodMutation.mutate(method.id)
                      }}
                      disabled={disableDefault}
                    >
                      Set default
                    </button>
                    <button
                      className="delete-container"
                      onClick={() => {
                        setDisableDelete(true)
                        stripeDetachPaymentMethodMutation.mutate(method.id)
                      }}
                      disabled={disableDelete}
                    >
                      <DeleteIcon height={20} width={20} />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </Container>
        )}
        <div className="d-flex justify-content-between">
          <Button
            className="mt-3 rounded-pill font-bold-14 text-white plaid-btn btn btn-primary"
            onClick={() => setShowNewPaymentMethod(true)}
          >
            Add new card
          </Button>
          {showReturnToArticle && (
            <Button
              className="mt-3 rounded-pill font-bold-14 text-white plaid-btn btn btn-secondary"
              disabled={!stripeDefaultPaymentMethod}
              onClick={() => nav(`/${APPCONFIG.routes.backToArticle}`)}
            >
              Return to article
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default StripePaymentMethod
