import { useEffect, useState } from "react"
import { useGetDashboardInfo } from "../../../api/queries"
import { IViewsProps } from "../Drawer/utils"
import "./DashboardModule.scss"
import { IDashboardModule } from "../../../utils/types/accountSetup"
import UserKPIs from "./components/UsersKPIs"
import ArticlesKPIs from "./components/ArticlesKPIs"
import DaysButtons from "./components/DaysButtons"
import Passes from "./components/Passes"
import NormalCard from "./components/NormalCard"

const dataInfo = {
  active_passess: 0,
  articles_sold: 0,
  day_passes: 0,
  day_passes_revenue: 0,
  invested_articles: 0,
  invested_plans: 0,
  month_passes: 0,
  month_passes_revenue: 0,
  new_registration: 0,
  total_articles_sold: 0,
  week_passes: 0,
  week_passes_revenue: 0,
  year_passes: 0,
  year_passes_revenue: 0
}
const DashboardModule = ({ selectedSite }: IViewsProps) => {
  const [days, setDays] = useState<number>(30)
  const [info, setinfo] = useState<IDashboardModule>(dataInfo)
  const { dashboardInfo } = useGetDashboardInfo(selectedSite?.api_key ?? "", days)
  useEffect(() => {
    if (!dashboardInfo) {
      setinfo(dataInfo)
      return
    }
    setinfo(dashboardInfo)
  }, [dashboardInfo])

  return (
    <>
      <div className="nbbl-dashboard-container">
        <div className="nbbl-dashboard-header">
          <div
            className="nbbl-card-text"
            style={{
              fontSize: 24,

              fontWeight: "500"
            }}
          >
            NBBL Dashboard
          </div>
        </div>
        <UserKPIs active_passess={info.active_passess} invested_plans={info.invested_plans} />
        <ArticlesKPIs
          articles_sold={info.articles_sold}
          invested_articles={info.invested_articles}
        />
        <div
          className="nbbl-section-cont"
          style={{
            alignItems: "flex-start",
            gap: 10,
            width: "100%"
          }}
        >
          <div
            className="nbbl-card-text"
            style={{
              fontSize: 24,

              fontWeight: "500"
            }}
          >
            Passes KPIs
          </div>
          <DaysButtons days={days} setDays={setDays} value={30} />
          <DaysButtons days={days} setDays={setDays} value={90} />
          <DaysButtons days={days} setDays={setDays} value={180} />
          <DaysButtons days={days} setDays={setDays} value={365} />
        </div>
        <div className="nbbl-section-cont">
          <Passes type={"Day Passes"} count={info.day_passes} revenue={info.day_passes_revenue} />
          <Passes
            type={"Week Passes"}
            count={info.week_passes}
            revenue={info.week_passes_revenue}
          />
          <Passes
            type={"Month Passes"}
            count={info.month_passes}
            revenue={info.month_passes_revenue}
          />
          <Passes
            type={"Year Passes"}
            count={info.year_passes}
            revenue={info.year_passes_revenue}
          />
        </div>
        <div style={{ paddingBottom: "5%", display: "flex", gap: 30 }}>
          <NormalCard label={"New Registrations"} info={info.new_registration} />
          <NormalCard label={"Total sold articles"} info={info.total_articles_sold} />
        </div>
      </div>
    </>
  )
}

export default DashboardModule
