import { useEffect, useRef, useState } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"
import { APPCONFIG } from "../../../app-config"
import { IPublisherSite } from "../../../utils/types/publisherSite"
import { IIsAuthenticated } from "../../../utils/types/isAuthenticated"
import { isPublisherAuthenticated } from "../../../api/request"
import toast from "react-hot-toast"

export interface IViewsProps {
  selectedSite: IPublisherSite | undefined
  setHasChanges?: React.Dispatch<React.SetStateAction<boolean>>
  hasChanges?: boolean
  setSelectedSite?: React.Dispatch<React.SetStateAction<IPublisherSite | undefined>>
}

export const useRedirection = () => {
  const nav = useNavigate()
  const [validationData, setValidationData] = useState<IIsAuthenticated>({
    is_authenticated: true,
    is_publisher: false
  })
  const validationFetched = useRef<boolean>(false)

  const [queryParameters] = useSearchParams()

  // Fetch validation data
  useEffect(() => {
    if (validationFetched.current) return

    const urlAccessToken = queryParameters.get("access_token")
    if (!!urlAccessToken) localStorage.setItem(APPCONFIG.localVars.token, urlAccessToken)

    const accessToken = localStorage.getItem(APPCONFIG.localVars.token)
    if (!accessToken) {
      validationFetched.current = true
      setValidationData({ is_authenticated: false })
      return
    }

    isPublisherAuthenticated({
      access_token: accessToken,
      is_publisher: true
    }).then(
      (response) => {
        setValidationData(response.payload)
        validationFetched.current = true
      },
      () => {
        toast.error("There was an error fetching validation data")
        setValidationData((prevState) => ({ ...prevState, is_authenticated: false }))
        validationFetched.current = true
      }
    )
  }, [queryParameters])

  // Redirect based on validationData
  useEffect(() => {
    if (!validationFetched.current) return
    if (!validationData.is_authenticated || !validationData.is_publisher) {
      nav(`/${APPCONFIG.routes.publisher.login}`)
      return
    }
    nav(APPCONFIG.routes.publisher.accountSetup)

    // check every 30 seconds if access token is expired
    if (!validationData?.expires_at) return
    const intervalCheck = setInterval(() => {
      console.log("interval")
      const expirationDate = new Date(validationData.expires_at ?? "")
      if (expirationDate <= new Date())
        setValidationData(() => ({ ...validationData, is_authenticated: false }))
    }, 30000)

    return () => clearInterval(intervalCheck)
  }, [validationData])

  return { validationData }
}
