export const customStyles = {
  headCells: {
    style: {
      background: "#F5F5F5",
      color: "black",
      fontSize: 14,
      fontFamily: "Karla",
      fontWeight: "700"
    }
  },
  cells: {
    style: {
      color: "black",
      fontSize: 16,
      fontFamily: "Karla",
      fontWeight: "500",
      wordWrap: "break-word"
    }
  }
}

export const getDate = (date: string, DMY: string) => {
  const dateRow = new Date(date)
  let month = dateRow.toLocaleString("default", { month: "short" })
  month = DMY === "month" ? month.charAt(0).toUpperCase() + month.slice(1) : month
  const dateObj = {
    month,
    day: dateRow.getDate(),
    year: dateRow.getFullYear()
  }
  return dateObj[DMY]
}

export const formatDate = (dateParam: string) => {
  const date = new Date(dateParam)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  const formattedDate = `${month}-${day}-${year}`

  return formattedDate
}
