import { IPublisherSite } from "../../../utils/types/publisherSite"

export const subscriptionManagementRoutes = {
  subscriptions: "subscriptions",
  transactionHistory: "transactionHistory"
}

export const KeysIFormState = [
  "single_article_low",
  "single_article_mid",
  "single_article_high",
  "single_article_custom",
  "day_pass",
  "week_pass",
  "month_pass",
  "year_pass"
]

export interface IFormState {
  single_article_low: number
  single_article_mid: number
  single_article_high: number
  single_article_custom: number
  day_pass: number
  week_pass: number
  month_pass: number
  year_pass: number
}

export interface ISubscriptionManagement extends IFormState {
  hot_price_time: number
  hot_price: number
  ts_price_1: number
  duration_ts_1: number
  final_price_ts_1: string
  ts_price_2: number
  duration_ts_2: number
  final_price_ts_2: string
  ts_price_custom: number
  duration_ts_custom: number
  final_price_ts_custom: string
}
export const formState: ISubscriptionManagement = {
  single_article_low: 0,
  single_article_mid: 0,
  single_article_high: 0,
  single_article_custom: 0,
  day_pass: 0,
  week_pass: 0,
  month_pass: 0,
  year_pass: 0,
  hot_price_time: 0,
  hot_price: 0,
  ts_price_1: 0,
  duration_ts_1: 0,
  final_price_ts_1: "",
  ts_price_2: 0,
  duration_ts_2: 0,
  final_price_ts_2: "",
  ts_price_custom: 0,
  duration_ts_custom: 0,
  final_price_ts_custom: ""
}

export const PlansTypes = {
  single_article: 1,
  day_pass: 2,
  week_pass: 3,
  month_pass: 4,
  year_pass: 5
}

export interface ISubscriptionViews {
  readOnly: boolean
  values?: any
  selectedSite?: IPublisherSite
  days?: number
}

export const SensitiveNames = {
  1: "TIME SENSITIVE 1",
  2: "TIME SENSITIVE 2",
  custom: "TIME SENSITIVE CUSTOM"
}

export const getKeyTimeSensitive = (name) => {
  if (name === SensitiveNames[1]) {
    return "1"
  } else if (name === SensitiveNames[2]) {
    return "2"
  } else if (name === SensitiveNames.custom) {
    return "custom"
  }
}
