import React, { FC, useEffect } from "react"
import { useParams } from "react-router-dom"
import { get_auth_url } from "../../api/request"
import { APPCONFIG } from "../../app-config"
import "./Login.scss"

var Buffer = require("buffer/").Buffer

interface ITrusonaLogin {
  profile: "publisher" | "user"
}
const TrusonaLogin: FC<ITrusonaLogin> = (props) => {
  const { profile } = props

  const { widgetData } = useParams()
  useEffect(() => {
    if (widgetData) {
      const decodedWidgetData = Buffer.from(widgetData, "base64").toString("ascii")
      localStorage.setItem(APPCONFIG.localVars.widgetData, decodedWidgetData)
    }

    const fetchAuthURL = async () => {
      const response = await get_auth_url(profile)
      window.location.href = response.payload
    }
    fetchAuthURL()
  }, [])

  return <div />
}
export default TrusonaLogin
