import { Button, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import TextInput from "../../../../../../customComponents/TextInput"
import { useCreatePublisherSite } from "../../../../../../api/mutations"
import { toast } from "react-hot-toast"
import { useQueryClient } from "react-query"
import { IPublisherSite } from "../../../../../../utils/types/publisherSite"
interface ModalCreateProps {
  handlerCancel: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
  publisher: number
  publisherSites: IPublisherSite[] | undefined
  setSelectedSite: React.Dispatch<React.SetStateAction<IPublisherSite | undefined>>
}

const ModalCreateNewSite = ({
  handlerCancel,
  show,
  publisher,
  setSelectedSite,
  publisherSites
}: ModalCreateProps) => {
  const queryClient = useQueryClient()
  const useCreatePublisherMutation = useCreatePublisherSite()
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    validationSchema: Yup.object().shape({
      siteName: Yup.string().required(),
      siteURL: Yup.string().required(),
      bankRoutingNumber: Yup.number().min(1).required(),
      bankAccountNumber: Yup.number().min(1).required()
    }),
    initialValues: {
      siteName: "",
      siteURL: "https://",
      publisher: publisher,
      bankRoutingNumber: 0,
      bankAccountNumber: 0
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.siteName,
        url: values.siteURL,
        publisher: values.publisher,
        bank_routing_number: values.bankRoutingNumber,
        bank_account_number: values.bankAccountNumber
      }
      useCreatePublisherMutation.mutate(data, {
        onSuccess: (response) => {
       
          setSelectedSite(response.payload)
          publisherSites && publisherSites.push(response.payload)
          queryClient.setQueryData(["publisher_sites"], publisherSites)
          toast.success("New site was successfully created")
          resetForm()
          handlerCancel(false)
        },
        onError: (error) => {
          toast.error("There was an error when trying to create the site")
        }
      })
    }
  })
  const RequiredError = () => <div className="validation-error mb-4">Required field</div>
  return (
    <>
      <Modal show={show} onHide={() => handlerCancel(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Site</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <TextInput
              label="Site Name"
              name={"siteName"}
              type={"text"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.siteName}
            />
            {errors.siteName && touched.siteName ? <RequiredError /> : null}
            <TextInput
              label="Site URL"
              name={"siteURL"}
              type={"url"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.siteURL}
            />
            {errors.siteURL && touched.siteURL ? <RequiredError /> : null}
            <TextInput
              label="Bank Routing Number"
              name={"bankRoutingNumber"}
              type={"number"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.bankRoutingNumber}
            />
            {errors.bankRoutingNumber && touched.bankRoutingNumber ? <RequiredError /> : null}
            <TextInput
              label="Bank Account Number"
              name={"bankAccountNumber"}
              type={"number"}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.bankAccountNumber}
            />
            {errors.bankAccountNumber && touched.bankAccountNumber ? <RequiredError /> : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handlerCancel(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create Site
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ModalCreateNewSite
