import React, { FC, useEffect } from "react"
import { Button } from "react-bootstrap"
import { useFormik } from "formik"
import { useAccountSetupQuery } from "../../../../api/queries"
import { useAccountSetupMutation, usePublisherSiteMutation } from "../../../../api/mutations"
import { useQueryClient } from "react-query"
import * as Yup from "yup"
import TextInput from "../../../../customComponents/TextInput"
import { useNavigate } from "react-router-dom"
import { APPCONFIG } from "../../../../app-config"
import { IAccountSetup } from "../../../../utils/types/accountSetup"
import { toast } from "react-hot-toast"

interface IRegistrationForm {
  apiKey: string
}
const RegistrationForm: FC<IRegistrationForm> = (props) => {
  const nav = useNavigate()

  const { apiKey } = props
  const queryClient = useQueryClient()
  const { accountSetup } = useAccountSetupQuery(apiKey)
  const accountSetupMutation = useAccountSetupMutation()
  const publisherSiteMutation = usePublisherSiteMutation()

  const { handleSubmit, handleChange, handleBlur, setValues, values, errors, touched } = useFormik({
    validationSchema: Yup.object().shape({
      siteName: Yup.string().required(),
      siteURL: Yup.string().required(),
      bankRoutingNumber: Yup.number().required(),
      bankAccountNumber: Yup.number().required()
    }),
    initialValues: {
      siteName: accountSetup?.publisher_site.name ?? "",
      siteURL: "https://",
      bankRoutingNumber: accountSetup?.bank_routing_number ?? "",
      bankAccountNumber: accountSetup?.bank_account_number ?? ""
    },
    enableReinitialize:true,
    onSubmit: async (values) => {
      if (!accountSetup) return
      const updatedAccountSetup: IAccountSetup = {
        ...accountSetup,
        bank_routing_number: values.bankRoutingNumber,
        bank_account_number: values.bankAccountNumber,
        publisher_site: {
          ...accountSetup.publisher_site,
          name: values.siteName,
          url: values.siteURL
        }
      }
      publisherSiteMutation.mutate(updatedAccountSetup.publisher_site, {
        onSuccess: () =>
          accountSetupMutation.mutate(
            { accountSetup: updatedAccountSetup, apiKey: apiKey },
            {
              onSuccess: () => {
                queryClient.setQueryData(["accountSetup", apiKey], updatedAccountSetup)
                nav(APPCONFIG.routes.publisher.accountSetup)
              },
              onError: () => {
                toast.error("Error saving account setup")
              }
            }
          ),
        onError: () => {
          toast.error("Erorr saving publisher site")
        }
      })
    }
  })

  useEffect(() => {
    if (!accountSetup) return
    setValues({
      siteName: accountSetup.publisher_site.name,
      siteURL: accountSetup.publisher_site.url,
      bankRoutingNumber: accountSetup.bank_routing_number,
      bankAccountNumber: accountSetup.bank_account_number
    })
  }, [accountSetup])

  const RequiredError = () => <div className="validation-error mb-4">Required field</div>

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        label="Site Name"
        name={"siteName"}
        type={"text"}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.siteName}
      />
      {errors.siteName && touched.siteName ? <RequiredError /> : null}
      <TextInput
        label="Site URL"
        name={"siteURL"}
        type={"url"}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.siteURL}
      />
      {errors.siteURL && touched.siteURL ? <RequiredError /> : null}
      <TextInput
        label="Bank Routing Number"
        name={"bankRoutingNumber"}
        type={"number"}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.bankRoutingNumber}
      />
      {errors.bankRoutingNumber && touched.bankRoutingNumber ? <RequiredError /> : null}
      <TextInput
        label="Bank Account Number"
        name={"bankAccountNumber"}
        type={"number"}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.bankAccountNumber}
      />
      {errors.bankAccountNumber && touched.bankAccountNumber ? <RequiredError /> : null}
      <h6 className="w-100">The bank account information will be used only for payout purposes</h6>
      <Button className="rounded-pill w-100 mt-2 register-btn" type="submit">
        Login
      </Button>
    </form>
  )
}

export default RegistrationForm
