import DatePicker from "react-datepicker"
import { downloadCSV } from "../../utils"

interface IActionsTableProps {
  handlerSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlerDateSet: (update: Date[]) => void
  handerExport: () => void
  startDate: Date | null
  endDate: Date | null
}

const ActionsTable = ({
  handlerSearch,
  handlerDateSet,
  startDate,
  endDate,
  handerExport
}: IActionsTableProps) => {
  return (
    <>
      <div className="nbbl-customer-table-container">
        <input
          type="text"
          name="search-input"
          placeholder="Search"
          className="nbbl-customer-search"
          onChange={handlerSearch}
        />
        <div className="nbbl-date-cont">
          <div className="nbbl-date-label">Filter by Date</div>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd.MM"
            onChange={handlerDateSet}
          />
        </div>
        <div className="nbbl-export-button" onClick={handerExport}>
          <div className="nbbl-export-button-text">Export data</div>
        </div>
      </div>
    </>
  )
}

export default ActionsTable
