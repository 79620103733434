export function convertArrayOfObjectsToCsv(array) {
  let result

  const columnDelimiter = ","
  const lineDelimiter = "\n"
  const keys = Object.keys(array[0])

  result = ""
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter

      result += item[key]

      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export function downloadCSV(array) {
  const link = document.createElement("a")
  let xls = convertArrayOfObjectsToCsv(array)
  if (xls == null) return

  const filename = "export.csv"

  if (!xls.match(/^data:text\/csv/i)) {
    xls = `data:text/csv;charset=utf-8,${xls}`
  }

  link.setAttribute("href", encodeURI(xls))
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
