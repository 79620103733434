import { useQuery } from "react-query"
import {
  getAccountSetup,
  getArticlesViewHistory,
  getCustomers,
  getCustomersPlans,
  getDashboardInfo,
  getStripePaymentMethods,
  getPublisherSites,
  getSubcriptionManagement,
  getTransactionsHistory,
  getStripeDefaultPaymentMethod,
  getFrontendInfo
} from "./request"

import { APPCONFIG } from "../app-config"

export const useAccountSetupQuery = (apiKey: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["accountSetup", apiKey],
    queryFn: () => getAccountSetup(apiKey)
  })

  return {
    accountSetup: data?.payload,
    isFetchingAccountSetup: isFetching,
    refetchAccountSetup: refetch
  }
}

export const useGetPublisherSites = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["publisher_sites"],
    queryFn: async () => (await getPublisherSites()).payload.results,
    enabled: !!localStorage.getItem(APPCONFIG.localVars.token)
  })
  const publisherSites = data
  return {
    publisherSites,
    isFetchingPublisherSites: isFetching,
    refetchPublisher: refetch
  }
}

export const useGetSubcriptionManagement = (apiKey: string) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [`subcription_management_${apiKey}`, apiKey],
    queryFn: () => getSubcriptionManagement(apiKey)
  })

  const subcriptionManagement = data?.payload
  return {
    subcriptionManagement,
    isFetchingSubcriptionManagement: isFetching,
    refetchSubcriptionManagement: refetch
  }
}

export const useGetArticlesViewHistory = (
  apiKey: string,
  searchParam: string,
  page: number,
  from: Date | null,
  to: Date | null,
  pageSize: number
) => {
  const fromFormat = from ? formatDate(from) : ""
  const toFormat = to ? formatDate(to) : ""
  const { data, isFetching, refetch } = useQuery({
    queryKey: [
      `subcription_management_${apiKey}`,
      searchParam,
      page,
      fromFormat,
      toFormat,
      pageSize
    ],
    queryFn: () => getArticlesViewHistory(apiKey, searchParam, page, fromFormat, toFormat, pageSize)
  })

  const articlesViewHistory = data?.payload
  return {
    articlesViewHistory,
    isFetchingArticlesViewHistory: isFetching,
    refetchArticlesViewHistory: refetch
  }
}

export const useGetCustomer = (
  apiKey: string,
  searchParam: string,
  page: number,
  from: Date | null,
  to: Date | null,
  pageSize: number
) => {
  const fromFormat = from ? formatDate(from) : ""
  const toFormat = to ? formatDate(to) : ""
  const { data, isFetching, refetch } = useQuery({
    queryKey: [`customer_${apiKey}`, searchParam, page, from, to],
    queryFn: () => getCustomers(apiKey, searchParam, page, fromFormat, toFormat, pageSize)
  })

  const customerList = data?.payload
  return {
    customerList,
    isFetchingCustomerList: isFetching,
    refetchCustomerList: refetch
  }
}

export const useGetCustomerPlans = (
  apiKey: string,
  email: string,
  pageSize: number,
  page: number
) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [`customer_${apiKey}`, email, pageSize, page],
    queryFn: () => getCustomersPlans(apiKey, email, pageSize, page)
  })

  const customerPlans = data?.payload
  return {
    customerPlans,
    isFetchingCustomerPlans: isFetching,
    refetchCustomerPlans: refetch
  }
}

export const useGetDashboardInfo = (apiKey: string, days: number) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [`dashboard_${apiKey}`, days],
    queryFn: () => getDashboardInfo(apiKey, days),
    onError: () => {}
  })

  let dashboardInfo = data?.payload

  return {
    dashboardInfo,
    isFetchingDashboardInfo: isFetching,
    refetchDashboardInfo: refetch
  }
}

export const useGetTransactionHistory = (
  apiKey: string,
  searchParam: string,
  page: number,
  days: number
) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [`transactions_history${apiKey}`, searchParam, page, days],
    queryFn: () => getTransactionsHistory(apiKey, searchParam, page, days)
  })

  const transactionsHistory = data?.payload.results

  return {
    transactionsHistory,
    isFetchingTransactionsHistory: isFetching,
    refetchTransactionsHistory: refetch
  }
}

export const useStripePaymentMethodsQuery = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["stripe-payment-methods"],
    queryFn: () => getStripePaymentMethods()
  })

  return {
    stripePaymentMethods: data?.payload.data,
    isFetchingStripePaymentMethods: isFetching,
    refetchStripePaymentMethods: refetch
  }
}

export const useStripeDefaultPaymentMethodQuery = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["stripe-default-payment-method"],
    queryFn: () => getStripeDefaultPaymentMethod()
  })

  return {
    stripeDefaultPaymentMethod: data?.payload,
    isFetchingDefaultPaymentMethod: isFetching
  }
}

export const useFrontendInfoQuery = () => {
  const { data } = useQuery(["frontend-info"], () => getFrontendInfo(), {
    retry: true,
    refetchOnMount: false
  })

  return {
    frontendInfo: data?.payload
  }
}

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}
