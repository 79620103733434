import { useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"
import PlaidContainer from "../../components/PlaidContainer"
import { useNavigate, useParams } from "react-router-dom"
import "./AddPaymentMethod.scss"
import { APPCONFIG } from "../../app-config"
import Modal from "../../components/Modal"
var Buffer = require("buffer/").Buffer

type methodType = "plaid" | "stripe"

export default function AddPaymentMethod() {
  const { widgetData } = useParams()
  const [paymentMethod, setPaymentMethod] = useState<methodType>()
  const [showStripeDisclaimer, setShowStripeDisclaimer] = useState<boolean>(false)

  const nav = useNavigate()

  useEffect(() => {
    if (!widgetData) return
    const decodedWidgetData = Buffer.from(widgetData, "base64").toString("ascii")
    localStorage.setItem(APPCONFIG.localVars.widgetData, decodedWidgetData)

    const widgetDataJsonObject = JSON.parse(decodedWidgetData)
    localStorage.setItem(APPCONFIG.localVars.token, widgetDataJsonObject.accessToken)
  }, [widgetData])

  const openSelectedPaymentMethodModule = () => {
    if (paymentMethod === "plaid") {
      nav(`/${APPCONFIG.routes.connectYourBank}`)
    } else if (paymentMethod === "stripe") {
      nav(`/${APPCONFIG.routes.connectYourStripe}`)
    }
  }

  return (
    <div className="parent-div">
      <Modal
        show={showStripeDisclaimer}
        handleClose={() => setShowStripeDisclaimer(false)}
        onAccept={() => setPaymentMethod("stripe")}
        acceptLabel="Accept"
        onCancel={() => {}}
        cancelLabel="Cancel"
        title="Stripe Disclaimer"
        body="Stripe charges 2.9% + 30 cents over all transactions. This means if you choose to add $10 to your NBBL wallet then $10.59 will be charged. Do you wish to continue?"
      />
      <PlaidContainer cardClassName="card-container">
        <Container className="px-95 text-center mt-5 d-flex flex-column flex-grow-1">
          <div className="flex-grow-1">
            <p className="font-medium-24">Add Payment Method</p>
            {/* <Container
              onClick={() => setPaymentMethod("plaid")}
              className={`payment-method-tile ${
                paymentMethod === "plaid" ? "payment-method-tile-selected" : "payment-method-tile"
              }`}
            >
              <label className="m-1">PLAID</label>
            </Container> */}
            <Container
              onClick={() => setShowStripeDisclaimer(true)}
              className={`payment-method-tile ${
                paymentMethod === "stripe" ? "payment-method-tile-selected" : "payment-method-tile"
              }`}
            >
              <label className="m-1 clickable">Stripe</label>
            </Container>
          </div>

          <Button
            className="rounded-pill font-bold-14 text-white btn"
            style={{ marginBottom: "40px" }}
            onClick={() => {
              openSelectedPaymentMethodModule()
            }}
            disabled={!paymentMethod}
          >
            Continue to adding funds
          </Button>
        </Container>
      </PlaidContainer>
    </div>
  )
}
