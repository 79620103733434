import React, { useEffect } from "react"
import PlaidContainer from "../../components/PlaidContainer"
import { Button, Container } from "react-bootstrap"
import Checkbox from "../../customComponents/Checkbox"
import StripePaymentMethod from "../../components/StripePaymentMethod"
import { useNavigate, useParams } from "react-router-dom"
import { APPCONFIG } from "../../app-config"

var Buffer = require("buffer/").Buffer

const ChoosePaymentMethod = () => {
  const { widgetData } = useParams()

  const nav = useNavigate()

  useEffect(() => {
    if (!widgetData) return
    const decodedWidgetData = Buffer.from(widgetData, "base64").toString("ascii")
    localStorage.setItem(APPCONFIG.localVars.widgetData, decodedWidgetData)

    const widgetDataJsonObject = JSON.parse(decodedWidgetData)
    localStorage.setItem(APPCONFIG.localVars.token, widgetDataJsonObject.accessToken)
  }, [widgetData])

  const PaymentCheckbox = () => (
    <Checkbox
      checked={true}
      size={20}
      backgroundColor="#D0D0D0"
      accentColor="#1380ff"
      containerStyle={{ marginRight: 15 }}
    />
  )
  return (
    <PlaidContainer>
      <Container className="px-95 text-center mt-5 d-flex flex-column flex-grow-1">
        <p className="font-medium-24">Choose your payment method</p>
        <div className="d-flex align-items-center">
          <PaymentCheckbox />
          <span className="font-medium-24">Stripe</span>
        </div>
        <StripePaymentMethod />
        <div className="d-flex flex-column gap-3">
          <Button
            className="rounded-pill"
            onClick={() => nav(`/${APPCONFIG.routes.addPaymentMethod}`)}
          >
            Add new payment method
          </Button>
          <Button
            className="rounded-pill btn-secondary"
            onClick={() => nav(`/${APPCONFIG.routes.backToArticle}`)}
          >
            Return to article
          </Button>
        </div>
      </Container>
    </PlaidContainer>
  )
}

export default ChoosePaymentMethod
