import { useState } from "react"
import { FormState, FormViewsProps } from "../../AccountSetup"
import { InputComponent } from "../InputComponent"
import "./AccountConfiguration.scss"
import { useField } from "formik"

const AccountConfiguration = ({ initialValues, logo }: FormViewsProps) => {
  const [favicon, setFavicon] = useState<string | undefined>(logo)
  const [errorIcon, setErrorIcon] = useState<boolean>(false)
  const [field, mata, helpers] = useField("logo")

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    const maxSizeInBytes = 500 * 1024 // 500 KB
    if (file.size <= maxSizeInBytes) {
      setErrorIcon(false)
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const arrayBuffer = e.target?.result
        if (arrayBuffer instanceof ArrayBuffer) {
          const arrayBufferView = new Uint8Array(arrayBuffer)
          const blob = new Blob([arrayBufferView], { type: file.type })
          const url = URL.createObjectURL(blob)
          helpers.setValue(file)
          setFavicon(url)
        }
      }
      reader.readAsArrayBuffer(file)
    } else {
      setErrorIcon(true)
      setFavicon(undefined)
    }
  }
  return (
    <>
      <div className="nbbl-account-configuration-cont">
        <InputComponent
          name="publisher_email"
          label="Publisher Email"
          type="email"
          placeholder="Enter Publisher Email"
          readOnly={true}
          showCopyIcon={true}
        />

        <InputComponent name="site_url" label="Site URL" type="text" placeholder="Enter Site URL" />
        <InputComponent
          name="site_name"
          label="Site name"
          type="text"
          placeholder="Enter Site name"
        />
        <div style={{ display: "flex", alignItems: "center", marginBottom: "3%", marginTop: "1%" }}>
          <div className="nbbl-input-change-favicon">Change Favicon</div>
          <div className="nbbl-button-change-favicon">
            <label
              htmlFor={"id_files"}
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 8,
                display: "inline-flex"
              }}
            >
              <a role="button" aria-disabled="false" className="nbbl-upload-png">
                Upload your PNG favicon here
              </a>
            </label>
            <input
              id="id_files"
              type="file"
              name="logo"
              accept=".png, .ico"
              style={{ visibility: "hidden", position: "absolute" }}
              onChange={handleFileChange}
            />
          </div>
          {favicon && <img className="nbbl-input-logo" src={favicon} alt="site-logo" />}
        </div>
        {errorIcon && (
          <div style={{ fontSize: 16, color: "red", fontWeight: 700 }}>
            Please select a valid PNG file.
          </div>
        )}
      </div>
    </>
  )
}

export default AccountConfiguration
