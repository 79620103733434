export const NormalCard = ({ label, info }) => {
  return (
    <>
      <div className="nbbl-card-container">
        <div style={{ position: "relative" }}>
          <div
            className="nbbl-card-text"
            style={{
              fontSize: 18
            }}
          >
            {label}
          </div>
          <div
            className="nbbl-card-text"
            style={{
              fontSize: 32
            }}
          >
            {info}
          </div>
        </div>
      </div>
    </>
  )
}
