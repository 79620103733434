const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export const APPCONFIG = {
  backend: isDevelopment
    ? "http://localhost:8000/"
    : `https://hamiltons-33335${
        window.location.href.indexOf("staging") > -1 ? "-staging" : ""
      }.botics.co/`,
  routes: {
    home: "",
    trusonaLogin: "trusona-login",
    connectYourBank: "connect-your-bank",
    connectYourStripe: "connect-your-stripe",
    choosePaymentMethod: "choose-payment-method",
    addPaymentMethod: "add-payment-method",
    stripePaymentMethod: "stripe-payment-method",
    backToArticle: "back-to-article",
    addFunds: "add-funds",
    termsAndConditions: "terms-and-conditions",
    privacyPolicies: "privacy-policies",
    publisher: {
      home: "publisher/*",
      login: "publisher/trusona-login",
      registration: "publisher/registration",
      drawer: "publisher/drawer",
      accountSetup: "account-setup",
      subscriptionsManagement: "subscriptions-management",
      customerModule: "customer-module",
      dashboardModule: "dashboard-module"
    }
  },
  responseStatuses: {
    SIGNUP_USER_EXISTS: 422
  },
  localVars: {
    token: "accessToken",
    widgetData: "widgetData"
  },
  sessVars: {
    fundsToAdd: "fundsToAdd"
  },
  debug: {
    global: true,
    forms: false
  }
}
