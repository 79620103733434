import React, { useState } from "react"
import svg from "../../../../../assets/images/svg"
import { ButtonSideBar } from "../ButtonSideBar"
import "./SideBar.scss"
import { APPCONFIG } from "../../../../../app-config"
import SelectCustom from "../SelectCustom/SelectCustom"
import { logout } from "../../../../../api/request"
import { IPublisherSite } from "../../../../../utils/types/publisherSite"
import { toast } from "react-hot-toast"
import ModalCreateNewSite from "./components/ModalCreateNewSite"

interface SideBarProps {
  setActiveComponent: (comp: any) => void
  activeComponent: string
  publisherSites: IPublisherSite[] | undefined
  setSelectedSite: React.Dispatch<React.SetStateAction<IPublisherSite | undefined>>
  selectedSite: IPublisherSite | undefined
}

const SideBar = ({
  setActiveComponent,
  activeComponent,
  publisherSites = [],
  setSelectedSite,
  selectedSite
}: SideBarProps) => {
  const [showCreateSite, setShowCreateSite] = useState<boolean>(false)
  const handleLogout = () => {
    logout().then(
      () => {
        localStorage.removeItem(APPCONFIG.localVars.token)
        window.location.reload()
      },
      () => toast.error("An error ocurred while trying to logout")
    )
  }
  return (
    <>
      <div style={{ width: "16%", maxWidth: 305, minWidth: 240 }}>
        <div className="nbbl-side-bar-container">
          <img
            style={{ width: 122, height: 47 }}
            src={svg.NbblLogoBlack}
            className="nbbl-img"
            alt="nbbl-logo"
          />
          <div className="nbbl-sidebar-buttons-cont">
            <div className="nbbl-button-cont-1">
              <SelectCustom
                options={publisherSites}
                setSelectedSite={setSelectedSite}
                addNewSite={setShowCreateSite}
                selectedSite={selectedSite}
              ></SelectCustom>
              <ButtonSideBar
                text="Account Setup"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveComponent(APPCONFIG.routes.publisher.accountSetup)
                }}
                color="white"
                selected={APPCONFIG.routes.publisher.accountSetup === activeComponent}
                to={APPCONFIG.routes.publisher.accountSetup}
              />
              <ButtonSideBar
                text="Subscriptions Management"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveComponent(APPCONFIG.routes.publisher.subscriptionsManagement)
                }}
                color="white"
                selected={APPCONFIG.routes.publisher.subscriptionsManagement === activeComponent}
                to="subscriptions-management"
              />
              <ButtonSideBar
                text="Customers Module"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveComponent(APPCONFIG.routes.publisher.customerModule)
                }}
                color="white"
                selected={APPCONFIG.routes.publisher.customerModule === activeComponent}
                to="customer-module"
              />
              <ButtonSideBar
                text="Dashboard Module"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveComponent(APPCONFIG.routes.publisher.dashboardModule)
                }}
                color="white"
                selected={APPCONFIG.routes.publisher.dashboardModule === activeComponent}
                to="dashboard-module"
              />
            </div>
            <div className="nbbl-button-cont-2">
              <button
                className="nbbl-button-log-out"
                value="Logout"
                onClick={handleLogout}
                color="white"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <ModalCreateNewSite
          handlerCancel={setShowCreateSite}
          show={showCreateSite}
          publisher={selectedSite?.publisher ?? 0}
          setSelectedSite={setSelectedSite}
          publisherSites={publisherSites}
        />
      </div>
    </>
  )
}

export default SideBar
