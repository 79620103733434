import { useField, ErrorMessage, useFormikContext } from "formik"
import "./SubcriptionInputComponent.scss"

import { useEffect, useState } from "react"

interface InputComponentProps {
  label: string
  type: string
  readOnly?: boolean
  placeholder: string
  name: string
  inNbbl: boolean
}

export const SubcriptionInputComponent = ({
  label,
  type,
  readOnly = false,
  placeholder,
  name,
  inNbbl
}: InputComponentProps) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    helpers.setValue(value)
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="nbbl-input-component-container">
        <div className="nbbl-input-label">{label}</div>
        <span style={{ marginRight: "5px" }}>{inNbbl ? "$" : "NBBL"}</span>
        <input
          {...field}
          type={type}
          className="nbbl-input-component"
          readOnly={readOnly}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
