import React from "react"
import { useRoutes } from "react-router-dom"
import BackToArticle from "./components/BackToArticle"
import PlaidLink from "./components/PlaidLink"
import StripeLink from "./components/StripeLink"
import { APPCONFIG } from "./app-config"
import TermsAndConditions from "./screens/TermsAndConditions/TermsAndConditions"
import PrivacyPolicies from "./screens/PrivacyPolicies/PrivacyPolicies"
import AddPaymentMethod from "./screens/AddPaymentMethod"
import TrusonaLogin from "./screens/Login/TrusonaLogin"
import { Drawer } from "./screens/publisher/Drawer"
import { SiteRegistration } from "./screens/publisher/Registration"
import ChoosePaymentMethod from "./screens/ChoosePaymentMethod"
import StripeAddMethod from "./screens/StripeAddMethod"

const AppRoutes = () =>
  useRoutes([
    {
      path: `${APPCONFIG.routes.trusonaLogin}/:widgetData`,
      element: <TrusonaLogin profile={"user"} />
    },
    {
      path: `${APPCONFIG.routes.addPaymentMethod}/:widgetData?`,
      element: <AddPaymentMethod />
    },
    {
      path: `${APPCONFIG.routes.choosePaymentMethod}/:widgetData?`,
      element: <ChoosePaymentMethod />
    },
    {
      path: APPCONFIG.routes.connectYourBank,
      element: <PlaidLink />
    },
    {
      path: APPCONFIG.routes.stripePaymentMethod,
      element: <StripeAddMethod />
    },
    {
      path: APPCONFIG.routes.connectYourStripe,
      element: <StripeLink />
    },
    { path: APPCONFIG.routes.backToArticle, element: <BackToArticle /> },
    {
      path: APPCONFIG.routes.termsAndConditions,
      element: <TermsAndConditions />
    },
    { path: APPCONFIG.routes.privacyPolicies, element: <PrivacyPolicies /> },
    {
      path: APPCONFIG.routes.publisher.home,
      element: <Drawer />
    },
    {
      path: `${APPCONFIG.routes.publisher.login}`,
      element: <TrusonaLogin profile="publisher" />
    },
    {
      path: `${APPCONFIG.routes.publisher.registration}/:apiKey`,
      element: <SiteRegistration />
    }
  ])

export default AppRoutes
