import { FormState } from "../../AccountSetup"
import { InputComponent } from "../InputComponent"
import "./WordpressIntegration.scss"

interface WordpressIntegrationProps {
  initialValues: FormState
}

const WordpressIntegration = ({ initialValues }: WordpressIntegrationProps) => {
  return (
    <>
      <div style={{ position: "relative", marginTop: "3%" }}>
        <div
          style={{
            color: "black",
            fontSize: 24,
            fontFamily: "Karla",
            fontWeight: "500",
            wordWrap: "break-word"
          }}
        >
          Wordpress integration
        </div>
        <div
          style={{
            textAlign: "left",
            color: "black",
            fontSize: 14,
            fontFamily: "Karla",
            fontWeight: "700",
            wordWrap: "break-word"
          }}
        >
          The Api KEY should be pasted at the WordPress site. If you have doubts on how to do it,
          please watch the video
        </div>
        <InputComponent
          name="api_key"
          label="Api KEY"
          type="text"
          readOnly={true}
          placeholder=""
          showCopyIcon={true}
        />
      </div>
    </>
  )
}

export default WordpressIntegration
