import "./ButtonAccount.scss"

interface ButtonAccountProps {
  text: string
  onClick: () => void
  selected: boolean
}
const ButtonAccount = ({ text, onClick, selected }: ButtonAccountProps) => {
  return (
    <div
      className={`nbbl-button-account ${selected === true ? "nbbl-selected" : ""}`}
      onClick={onClick}
    >
      <div className="nbbl-button-account-text">{text}</div>
    </div>
  )
}

export default ButtonAccount
