import { FormState } from "../../AccountSetup"
import { InputComponent } from "../InputComponent"
import "./SMTPConfiguration.scss"

interface SMTPConfigurationProps {
  initialValues: FormState
}

const SMTPConfiguration = ({ initialValues }: SMTPConfigurationProps) => {
  return (
    <>
      <div style={{ position: "relative", marginTop: "3%" }}>
        <div style={{ height: 70 }}>
          <div
            style={{
              color: "black",
              fontSize: 24,
              fontFamily: "Karla",
              fontWeight: "500",
              wordWrap: "break-word"
            }}
          >
            Email SMTP Configuration
          </div>
          <div
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Karla",
              fontWeight: "700",
              wordWrap: "break-word"
            }}
          >
            To send confirmation payment emails from your publisher email, please complete the
            information below. Validate your email to avoid getting your email to spam
          </div>
        </div>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            display: "inline-flex",
            marginTop: "2%"
          }}
        >
          <InputComponent
            name="SMTP_email"
            type="email"
            label="Email"
            placeholder="example@gmail.com"
          />

          <InputComponent name="SMTP_host" type="text" label="Host" placeholder="Enter Host" />

          <InputComponent
            name="SMTP_username"
            type="text"
            label="Username"
            placeholder="Enter Username"
          />

          <InputComponent
            name="SMTP_password"
            type="password"
            label="Password"
            placeholder="Enter Password"
          />

          <InputComponent
            name="SMTP_port_number"
            type="text"
            label="Port number"
            placeholder="Enter Port number"
          />
        </div>
      </div>
    </>
  )
}

export default SMTPConfiguration
