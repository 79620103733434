import React from "react"
import PlaidContainer from "../../components/PlaidContainer"
import StripePaymentMethod from "../../components/StripePaymentMethod"

const StripeAddMethod = () => {
  return (
    <PlaidContainer>
      <StripePaymentMethod showReturnToArticle={true} />
    </PlaidContainer>
  )
}

export default StripeAddMethod
