import "./App.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router } from "react-router-dom"
import React from "react"
import AppRoutes from "./router"
import { QueryClient, QueryClientProvider } from "react-query"
import { Toaster } from "react-hot-toast"
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false }
  }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-center" />
      <Router>
        <AppRoutes />
      </Router>
    </QueryClientProvider>
  )
}

export default App
