import React, { FC } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import svg from "../../assets/images/svg"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

interface IPlaidContainer {
  children?: React.ReactNode
  hiddenHeader?: boolean
  containerClassName?: string
  cardClassName?: string
}
const PlaidContainer: FC<IPlaidContainer> = (props) => {
  const nav = useNavigate()
  const { children, hiddenHeader } = props
  return (
    <div className={`plaid_container ${props.containerClassName}`}>
      <Container className={`${props.cardClassName} plaid_card`}>
        <Row className="mt-3 px-4 py-3" hidden={hiddenHeader}>
          <Col xs={2}>
            <Button variant="light" onClick={() => nav(-1)}>
              &lt;
            </Button>
          </Col>
          <Col className="text-center">
            <img src={svg.NbblLogoBlack} alt="NBBL LOGO" />
          </Col>
          <Col xs={2} className=""></Col>
        </Row>
        {children}
      </Container>
    </div>
  )
}
export default PlaidContainer
