import { ErrorMessage } from "formik"
import { InputComponent } from "../../../AccountSetup/components/InputComponent"
import Checkbox from "../Checkbox"
import SubcriptionInputComponent from "../SubcriptionInputComponent"

import "./Subscriptions.scss"
import SelectSubscription from "../SelectSubscription"

const Subscriptions = ({ readOnly, values }) => {
  return (
    <>
      <div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="single_article_low"
              label="Single article (Low)"
              type="number"
              placeholder="Enter Single article value"
              readOnly={readOnly}
              inNbbl={readOnly}
            />
          </div>
          <ErrorMessage
            name={"single_article_low"}
            component="div"
            className="nbbl-error-message"
          />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="single_article_mid"
              label="Single article (Mid)"
              type="number"
              placeholder="Enter Single article value"
              readOnly={readOnly}
              inNbbl={readOnly}
            />
          </div>
          <ErrorMessage
            name={"single_article_mid"}
            component="div"
            className="nbbl-error-message"
          />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="single_article_high"
              label="Single article (High)"
              type="number"
              placeholder="Enter Single article value"
              readOnly={readOnly}
              inNbbl={readOnly}
            />
          </div>
          <ErrorMessage
            name={"single_article_high"}
            component="div"
            className="nbbl-error-message"
          />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="single_article_custom"
              label="Single article (Custom)"
              type="number"
              placeholder="Enter Single article value"
              readOnly={readOnly}
              inNbbl={readOnly}
            />
          </div>
          <ErrorMessage
            name={"single_article_custom"}
            component="div"
            className="nbbl-error-message"
          />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="day_pass"
              label="Day pass"
              type="number"
              placeholder="Enter Day pass value"
              readOnly={readOnly || !values?.day_pass_is_active}
              inNbbl={readOnly}
            />
            <Checkbox name="day_pass_is_active" />
          </div>
          <ErrorMessage name={"day_pass"} component="div" className="nbbl-error-message" />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="week_pass"
              label="Week pass"
              type="number"
              placeholder="Enter Week pass value"
              readOnly={readOnly || !values?.week_pass_is_active}
              inNbbl={readOnly}
            />
            <Checkbox name="week_pass_is_active" />
          </div>
          <ErrorMessage name={"week_pass"} component="div" className="nbbl-error-message" />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="month_pass"
              label="Monthly Subscription"
              type="number"
              placeholder="Enter monthly subscription value"
              readOnly={readOnly || !values?.month_pass_is_active}
              inNbbl={readOnly}
            />
            <Checkbox name="month_pass_is_active" />
          </div>
          <ErrorMessage name={"month_pass"} component="div" className="nbbl-error-message" />
        </div>
        <div>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <SubcriptionInputComponent
              name="year_pass"
              label="Annual Subscription"
              type="number"
              placeholder="Enter annual subscription value"
              readOnly={readOnly || !values?.year_pass_is_active}
              inNbbl={readOnly}
            />
            <Checkbox name="year_pass_is_active" />
          </div>
          <ErrorMessage name={"year_pass"} component="div" className="nbbl-error-message" />
        </div>
        <div>
          <div className="nbbl-ts-container">
            <InputComponent label="TS price 1" name="ts_price_1" placeholder="" type="number" />
            <InputComponent
              label="Duration (Hours)"
              name="duration_ts_1"
              placeholder=""
              type="number"
            />
            <SelectSubscription name="final_price_ts_1" />
          </div>
        </div>
        <div className="nbbl-ts-container">
          <InputComponent label="TS price 2" name="ts_price_2" placeholder="" type="number" />
          <InputComponent
            label="Duration (Hours)"
            name="duration_ts_2"
            placeholder=""
            type="number"
          />
          <SelectSubscription name="final_price_ts_2" />
        </div>
        <div className="nbbl-ts-container">
          <InputComponent
            label="TS price Custom"
            name="ts_price_custom"
            placeholder=""
            type="number"
          />
          <InputComponent
            label="Duration (Hours)"
            name="duration_ts_custom"
            placeholder=""
            type="number"
          />
          <SelectSubscription name="final_price_ts_custom" />
        </div>
      </div>
    </>
  )
}

export default Subscriptions
