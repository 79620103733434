import React, { useEffect, useRef, useState } from "react"

import "./switch.scss"

interface AppElementSwitchProps {
  checked: boolean | undefined
  onChange: () => void
}

export const AppElementSwitch = ({ checked = false, onChange }: AppElementSwitchProps) => {
  const [swith_checked, setChecked] = useState<boolean>(checked)
  return (
    <label className="nbbl-element__switch">
      <input
        type="checkbox"
        checked={swith_checked}
        onChange={() => {
          setChecked(!swith_checked)
          onChange()
        }}
      />
      <span className="knob"></span>
    </label>
  )
}
