import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

interface IModalPublisher {
  title: string
  text: string
  show: boolean
  handlerOk: () => void
  handlerCancel: () => void
}

const ModalPublisher = ({ title, text, handlerOk, handlerCancel, show }: IModalPublisher) => {
  return (
    <>
      <Modal show={show} onHide={handlerCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlerCancel}>
            Close
          </Button>
          <Button variant="primary" onClick={handlerOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalPublisher
