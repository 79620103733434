import React, { useEffect, useRef, useState } from "react"
import "./Drawer.scss"
import { SideBar } from "../AccountSetup/components/SideBar"
import { AccountSetup } from "../AccountSetup"
import { APPCONFIG } from "../../../app-config"
import { SubscriptionManagement } from "../SubscriptionManagement"
import { CustomerModule } from "../CustomerModule"
import DashboardModule from "../DashboardModule/DashboardModule"
import { useGetPublisherSites } from "../../../api/queries"
import { IViewsProps, useRedirection } from "./utils"
import ModalPublisher from "../ModalPublisher/ModalPublisher"
import { useNavigate } from "react-router-dom"
import { IPublisherSite } from "../../../utils/types/publisherSite"
import { Route, Routes } from "react-router-dom"

export interface IViews {
  title: string
  Content: ({ selectedSite }: IViewsProps) => JSX.Element
}

const Drawer = () => {
  //redirect
  const { validationData } = useRedirection()

  const nav = useNavigate()
  const { publisherSites, isFetchingPublisherSites } = useGetPublisherSites()
  const [selectedSite, setSelectedSite] = useState<IPublisherSite | undefined>(undefined)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [showModalPublisher, setShowModalPublisher] = useState<boolean>(false)
  useEffect(() => {
    if (!publisherSites) return
    setSelectedSite(publisherSites[0])
  }, [isFetchingPublisherSites])

  const [keepRoute, setKeepRoute] = useState<string>("")
  const [activeComponent, setactiveComponent] = useState<string>(
    APPCONFIG.routes.publisher.accountSetup
  )

  const handlerActiveComponent = (comp: string) => {
    if (hasChanges) {
      setShowModalPublisher(true)
      setKeepRoute(comp)
      return
    }
    nav(comp)
    setactiveComponent(comp)
  }

  const handlerContinueNav = () => {
    nav(keepRoute)
    setactiveComponent(keepRoute)
    setShowModalPublisher(false)
    setHasChanges(false)
    setKeepRoute("")
  }

  if (!validationData.is_authenticated || !validationData.is_publisher) return <></>
  return (
    <>
      <div className="nbbl-drawer-container">
        <SideBar
          setActiveComponent={handlerActiveComponent}
          activeComponent={activeComponent}
          publisherSites={publisherSites}
          setSelectedSite={setSelectedSite}
          selectedSite={selectedSite}
        ></SideBar>

        <Routes>
          <Route
            path={APPCONFIG.routes.publisher.accountSetup}
            element={
              <AccountSetup
                selectedSite={selectedSite}
                setHasChanges={setHasChanges}
                hasChanges={hasChanges}
                setSelectedSite={setSelectedSite}
              />
            }
          ></Route>
          <Route
            path={APPCONFIG.routes.publisher.subscriptionsManagement}
            element={<SubscriptionManagement selectedSite={selectedSite} />}
          ></Route>
          <Route
            path={APPCONFIG.routes.publisher.customerModule}
            element={<CustomerModule selectedSite={selectedSite} />}
          ></Route>
          <Route
            path={APPCONFIG.routes.publisher.dashboardModule}
            element={<DashboardModule selectedSite={selectedSite} />}
          ></Route>
        </Routes>
      </div>

      <ModalPublisher
        title="Account Setup"
        text="You will lose your uncommitted changes if you leave this page. Are you sure?"
        show={showModalPublisher}
        handlerCancel={() => setShowModalPublisher(false)}
        handlerOk={() => handlerContinueNav()}
      />
    </>
  )
}

export default Drawer
