import svg from "../../../../../../../assets/images/svg"
import GeneratePages from "../PageGenerator"
import "./PaginationComponent.scss"

export const PaginationComponent = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage)

  const handlePageChange = (newPage) => {
    onChangePage(newPage)
  }

  return (
    <div className="custom-pagination">
      <div
        className={`paginator ${currentPage === 1 ? " disabled" : ""}`}
        onClick={() => {
          const page = currentPage >= 2 ? currentPage - 1 : currentPage
          handlePageChange(page)
        }}
      >
        <img src={svg.ArrowLeft} alt="arrow-left" />
      </div>
      <GeneratePages
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />

      <div
        onClick={() => {
          const page = currentPage < totalPages ? currentPage + 1 : currentPage
          handlePageChange(page)
        }}
        className={`paginator ${currentPage === totalPages ? " disabled" : ""}`}
      >
        <img src={svg.ArrowRigth} alt="arrow-rigth" />
      </div>
    </div>
  )
}
