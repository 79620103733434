import { profileType } from "../utils/types/isAuthenticated"

const EndPoints = {
  auth_url: (profile: profileType) => `trusona/auth-url/?profile=${profile}`,
  logout: "trusona/logout/",
  plaidAuthTokenLink: "plaid/auth-token-link/",
  creatPlaidAccountDetails: "plaid/account-details/",
  termsAndConditions: "modules/terms-and-conditions/",
  privacyPolicies: "modules/privacy-policy/",
  stripeCustomerValidation: "stripe/api/v1/customer-validation/",
  registerPaymentIntent: "stripe/api/v1/payment-intent-registration/",
  createStripeCustomer: "stripe/api/v1/customer-creation/",
  createSetupIntent: "stripe/api/v1/setup-intent-creation/",
  attachPaymentMethod: "stripe/api/v1/attach-payment-method/",
  detachPaymentMethod: "stripe/api/v1/detach-payment-method/",
  getPaymentMethods: "stripe/api/v1/payment-methods/",
  defaultPaymentMethod: "stripe/api/v1/default-payment-method/",
  isPublisherAuthenticated: `trusona/validate-token/`,
  publisherSites: "publisher/api/v1/sites/",
  frontendInfo: "home/api/v1/frontend-info/",
  accountSetup: (apiKey: string) => `publisher/api/v1/${apiKey}/account-setup/`,
  updatePublisherSite: (id: number) => `publisher/api/v1/sites/${id}/`,
  getOrUpdateSubcriptionManagement: (apiKey: string) =>
    `publisher/api/v1/${apiKey}/subscription-management/`,
  getArticlesViewHistory: (
    apiKey: string,
    searchParam: string,
    page: number,
    from: string,
    to: string,
    pageSize: number
  ) =>
    `publisher/api/v1/${apiKey}/article-view-history/?search=${searchParam}&page=${page}&page_size=${pageSize}&date_to=${to}&date_from=${from}`,
  getCustomers: (
    apiKey: string,
    searchParam: string,
    page: number,
    from: string,
    to: string,
    pageSize: number
  ) =>
    `publisher/api/v1/${apiKey}/customer/?search=${searchParam}&page=${page}&page_size=${pageSize}&date_to=${to}&date_from=${from}`,

  getCustomersPlans: (apiKey: string, email: string, pageSize: number, page: number) =>
    `publisher/api/v1/${apiKey}/${email}/customer/?page=${page}&page_size=${pageSize}`,
  getDashboardInfo: (apiKey: string, days: number) =>
    `publisher/api/v1/${apiKey}/dashboard/?days=${days}`,
  getTrasanctionHistory: (apiKey: string, searchParam: string, page: number, days: number) =>
    `publisher/api/v1/${apiKey}/transactions-history/?search=${searchParam}&page=${page}&days=${days}`
}

export default EndPoints
