import React, { useEffect, useState } from "react"
import { IViewsProps } from "../Drawer/utils"
import { AppElementSwitch } from "./components/switch/switch"

import "./SubscriptionManagement.scss"
import svg from "../../../assets/images/svg"
import { ButtonAccount } from "../AccountSetup/components/ButtonAccount"
import { Subscriptions } from "./components/Subscriptions"
import { TransactionHistory } from "./components/TransactionHistory"
import {
  ISubscriptionManagement,
  ISubscriptionViews,
  KeysIFormState,
  PlansTypes,
  SensitiveNames,
  formState,
  getKeyTimeSensitive,
  subscriptionManagementRoutes
} from "./utils"
import { Form, Formik } from "formik"
import { useUpdateSubcriptionManagementMutation } from "../../../api/mutations"
import { useGetSubcriptionManagement } from "../../../api/queries"

import { subscriptionsValidationsSchema } from "../../../utils/validations"
import { IPlan } from "../../../utils/types/userPlan"

import { toast } from "react-hot-toast"

function SubscriptionManagement({ selectedSite }: IViewsProps) {
  const subscriptionViews = [
    {
      title: subscriptionManagementRoutes.subscriptions,
      Content: ({ readOnly, values }: ISubscriptionViews) => (
        <Subscriptions readOnly={readOnly} values={values} />
      )
    },
    {
      title: subscriptionManagementRoutes.transactionHistory,
      Content: ({ readOnly, selectedSite, days }: ISubscriptionViews) => (
        <TransactionHistory selectedSite={selectedSite} days={days} />
      )
    }
  ]
  const [days, setDays] = useState<number>(30)
  const [apiKey, setApiKey] = useState<string>("")
  const { subcriptionManagement, refetchSubcriptionManagement, isFetchingSubcriptionManagement } =
    useGetSubcriptionManagement(apiKey)
  const subcriptionManagementMutation = useUpdateSubcriptionManagementMutation()
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [activeComponent, setactiveComponent] = useState<string>(
    subscriptionManagementRoutes.subscriptions
  )
  const [initialState, setInitialState] = useState<ISubscriptionManagement>(formState)

  useEffect(() => {
    if (!selectedSite) return
    setApiKey(selectedSite.api_key)
    refetchSubcriptionManagement()
  }, [selectedSite])

  useEffect(() => {
    if (!subcriptionManagement) return
    const copyState = { ...initialState }
    if (readOnly) {
      KeysIFormState.forEach((key) => {
        if (copyState[key] !== 0) {
          copyState[key] = (copyState[key] / 100).toFixed(2)
        }
      })
      if (copyState.hot_price !== 0) {
        copyState.hot_price = parseFloat((copyState.hot_price / 100).toFixed(2))
      }
    } else {
      KeysIFormState.forEach((key) => {
        if (initialState[key] !== 0) {
          copyState[key] = (copyState[key] * 100).toFixed(0)
        }
      })
      if (copyState.hot_price !== 0) {
        copyState.hot_price = parseFloat((copyState.hot_price * 100).toFixed(0))
      }
    }
    setInitialState(copyState)
  }, [readOnly])
  useEffect(() => {
    if (!subcriptionManagement) return
    const copyState = {
      single_article_low: 0,
      single_article_mid: 0,
      single_article_high: 0,
      single_article_custom: 0,
      single_article_price_is_active: false,
      day_pass: 0,
      day_pass_is_active: false,
      week_pass: 0,
      week_pass_is_active: false,
      month_pass: 0,
      month_pass_is_active: false,
      year_pass: 0,
      year_pass_is_active: false,
      hot_price: subcriptionManagement.hot_price,
      hot_price_time: subcriptionManagement.hot_price_time,
      ts_price_1: 0,
      duration_ts_1: 0,
      final_price_ts_1: "",
      ts_price_2: 0,
      duration_ts_2: 0,
      final_price_ts_2: "",
      ts_price_custom: 0,
      duration_ts_custom: 0,
      final_price_ts_custom: ""
    }
    if (subcriptionManagement.plans?.length > 0) {
      subcriptionManagement.plans.forEach((plan) => {
        let planType = Object.keys(PlansTypes).find((key) => PlansTypes[key] === plan.type)
        if (!planType) return
        if (plan.type === 1) {
          planType = planType + "_" + plan.sub_type.toLowerCase()
        }
        copyState[planType] = parseFloat(plan.price.toString()).toFixed(0)
        const key = planType + "_is_active"
        copyState[key] = plan.is_active
      })
      if (subcriptionManagement?.time_sensitives?.length > 0) {
        subcriptionManagement.time_sensitives.forEach((time_sensitive) => {
          const key = getKeyTimeSensitive(time_sensitive.name)
          copyState["ts_price_" + key] = time_sensitive.price
          copyState["duration_ts_" + key] = time_sensitive.duration
          copyState["final_price_ts_" + key] = time_sensitive.default_plan
        })
      }
      setInitialState(copyState)
    } else {
      setInitialState(copyState)
    }
  }, [subcriptionManagement, isFetchingSubcriptionManagement])

  const [ContentComponent, setContentComponent] = useState<
    (({ readOnly, values, selectedSite, days }) => JSX.Element) | undefined
  >(() => subscriptionViews.find((item) => item.title === activeComponent)?.Content)

  const activeComponentHandler = (comp: string) => {
    setactiveComponent(comp)
    setContentComponent(() => subscriptionViews.find((item) => item.title === comp)?.Content)
  }

  const handlerSubmit = (values: ISubscriptionManagement) => {
    if (!subcriptionManagement) return
    if (!selectedSite) return
    const plans: IPlan[] = []
    KeysIFormState.forEach((key) => {
      const is_active = values[key + "_is_active"]
      const single_article = "single_article_"
      const sub_type = key.includes(single_article)
        ? key.replace(single_article, "").toUpperCase()
        : ""
      const type = key.includes(single_article) ? PlansTypes["single_article"] : PlansTypes[key]
      const plan: IPlan = {
        price: values[key],
        type,
        publisher_site: selectedSite.id,
        sub_type,
        is_active
      }
      if (readOnly) {
        plan.price = parseFloat((values[key] * 100).toFixed(2))
      }
      plans.push(plan)
    })
    const timeSensitives: any = []
    const sensitiveType = ["1", "2", "custom"]
    sensitiveType.forEach((sens) => {
      const name = SensitiveNames[sens]
      const price = values["ts_price_" + sens]
      const duration = values["duration_ts_" + sens]
      const plan = values["final_price_ts_" + sens]
      timeSensitives.push({
        name,
        price,
        duration,
        plan
      })
    })
    const data = {
      apiKey: selectedSite?.api_key,
      param: {
        plans,
        time_sesitives: timeSensitives,
        hot_price_time: values.hot_price_time,
        hot_price: values.hot_price
      }
    }

    subcriptionManagementMutation.mutate(data, {
      onSuccess() {
        //TODO: loader here
        toast.success("Updated subscription!")
      },
      onError() {
        toast.error("Error trying to update subscription")
      }
    })
  }
  return (
    <>
      <div className="nbbl-subscription-managment-container">
        <div className="nbbl-subscription-management-header">
          <div className="nbbl-subscription-management-header-title">Subscriptions Management</div>
          <div className="nbbl-prices-types-container">
            <div className="nbbl-prices-types-title">Show prices in </div>
            <div className="nbbl-prices-switch">
              USD
              <AppElementSwitch
                checked={true}
                onChange={() => {
                  setReadOnly(!readOnly)
                }}
              />
              NBBLs
            </div>
            {/* <img src={svg.Information} alt="more-info" className="nbbl-info-img" /> */}
          </div>
        </div>

        <div className="nbbl-subscription-buttons-nav">
          <ButtonAccount
            text="Subscriptions"
            onClick={() => {
              activeComponentHandler(subscriptionManagementRoutes.subscriptions)
            }}
            selected={subscriptionManagementRoutes.subscriptions === activeComponent}
          />
          <ButtonAccount
            text="Transaction history"
            onClick={() => {
              activeComponentHandler(subscriptionManagementRoutes.transactionHistory)
            }}
            selected={subscriptionManagementRoutes.transactionHistory === activeComponent}
          />
          {subscriptionManagementRoutes.transactionHistory === activeComponent && (
            <>
              <div className="nbbl-transaction-history-days">
                <div
                  className={`nbbl-transaction-history-buttons ${days === 30 ? "selected" : ""}`}
                  onClick={() => setDays(30)}
                >
                  <div className="nbbl-transaction-history-buttons-text">Last 30 days</div>
                </div>
                <div
                  className={`nbbl-transaction-history-buttons ${days === 90 ? "selected" : ""}`}
                  onClick={() => setDays(90)}
                >
                  <div className="nbbl-transaction-history-buttons-text">Last 90 days</div>
                </div>
                <div
                  className={`nbbl-transaction-history-buttons ${days === 365 ? "selected" : ""}`}
                  onClick={() => setDays(365)}
                >
                  <div className="nbbl-transaction-history-buttons-text">Last 365 days</div>
                </div>
              </div>
            </>
          )}
        </div>
        <Formik
          initialValues={initialState}
          enableReinitialize={true}
          onSubmit={handlerSubmit}
          validationSchema={subscriptionsValidationsSchema}
        >
          {({ dirty, values }) => {
            return (
              <Form>
                {ContentComponent && (
                  <ContentComponent
                    readOnly={readOnly}
                    values={values}
                    selectedSite={selectedSite}
                    days={days}
                  />
                )}
                {subscriptionManagementRoutes.subscriptions === activeComponent && (
                  <div className="nbbl-account-form-actions">
                    <div className="nbbl-account-form-buttons cancel">
                      <div className="nbbl-account-form-button-text">Cancel</div>
                    </div>
                    <button className="nbbl-account-form-buttons save" type="submit">
                      <div className="nbbl-account-form-button-text">Save changes</div>
                    </button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
      <div className="nbbl-subscription-managmen-cont">
        <div className="nbbl-subscription-managment-help">
          <div className="nbbl-subscription-managment-help-title">
            How NBBLs Subscriptions work{" "}
          </div>
          <div style={{ width: "80%" }}>
            <span
              style={{
                color: "black",
                fontSize: 14,
                fontFamily: "Karla",
                fontWeight: "700",
                wordWrap: "break-word"
              }}
            >
              Available Credits - NBBL Upgrade Credit Function: <br />
              <br />
              Looks up the total of the last <br />
            </span>
            <span
              style={{
                color: "black",
                fontSize: 14,
                fontFamily: "Karla",
                fontWeight: "700",
                wordWrap: "break-word"
              }}
            >
              24 hours
              <br />7 days
              <br />
              30 days
              <br />6 months
              <br />
            </span>
            <span
              style={{
                color: "black",
                fontSize: 14,
                fontFamily: "Karla",
                fontWeight: "700",
                wordWrap: "break-word"
              }}
            >
              of NBBL purchases. This amount is applied as a credit toward unlocking full access for
              a Day, Week, Month, and Year Pass.
              <br />
              <br />
              i.e: A user buys in less than 24 hours 6 articles of 10 nbbls each (with daily pass of
              60 nbbls price). Then the user will automatically have the 60 credits that costs the
              daily pass, so he will be upgraded to it
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionManagement
