import { useMutation, useQueryClient } from "react-query"
import {
  IPatchPublisherSite,
  updateSubcriptionManagement,
  updateAccountSetup,
  updatePublisherSite,
  registerPaymentIntent,
  updateStripeDefaultPaymentMethod,
  detachPaymentMethod,
  createPublisherSite
} from "./request"
import { IAccountSetup } from "../utils/types/accountSetup"
import { IPlan } from "../utils/types/userPlan"
import { toast } from "react-hot-toast"
import { useStripePaymentMethodsQuery } from "./queries"
import { ICreatePublisherSite } from "../utils/types/publisherSite"

interface IAccountSetupMutation {
  accountSetup: Partial<IAccountSetup>
  apiKey: string
}

export interface ISubscriptionManagementParam {
  plans: IPlan[]
  hot_price_time: number
  hot_price: number
}

export interface ISubscriptionManagementMutation {
  apiKey: string
  param: ISubscriptionManagementParam
}

export interface IStripeIntentRegistration {
  cents_amount: number
}

export interface IStripeResponse {
  success: boolean
}

export const useAccountSetupMutation = () =>
  useMutation((variables: IAccountSetupMutation) =>
    updateAccountSetup(variables.accountSetup, variables.apiKey)
  )

export const usePublisherSiteMutation = () =>
  useMutation((publisherSite: IPatchPublisherSite) => updatePublisherSite(publisherSite))

export const useUpdateSubcriptionManagementMutation = () =>
  useMutation((param: ISubscriptionManagementMutation) => updateSubcriptionManagement(param))

export const usePaymentIntentRegistrationMutation = () =>
  useMutation((param: IStripeIntentRegistration) => registerPaymentIntent(param))

export const useStripeDefualtPaymentMethodMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (defaultPaymentMethodId: string) =>
      updateStripeDefaultPaymentMethod({ id: defaultPaymentMethodId }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["stripe-default-payment-method"], response)
        toast.success("Default payment method updated")
      },
      onError: () => {
        toast.error("There was an error trying to update payment method")
      }
    }
  )
}

export const useStripeDetachPaymentMethodMutation = () => {
  const { refetchStripePaymentMethods } = useStripePaymentMethodsQuery()
  return useMutation((paymentMethod: string) => detachPaymentMethod(paymentMethod), {
    onSuccess: () => {
      refetchStripePaymentMethods()
      toast.success("Deleted payment method")
    },
    onError: () => {
      toast.error("An error ocurred while trying to delete payment method")
    }
  })
}

export const useCreatePublisherSite = () =>
  useMutation((publisherSite: ICreatePublisherSite) => createPublisherSite(publisherSite))
