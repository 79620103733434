import { useEffect, useRef, useState } from "react"
import { Button, Container } from "react-bootstrap"
import svg from "../../assets/images/svg"
import PlaidContainer from "../PlaidContainer"
import { usePlaidLink } from "react-plaid-link"
import { plaidCreateAccountDetails, plaidAuthTokenLink } from "../../api/request"
import { useNavigate } from "react-router-dom"

import "./styles.scss"
import { APPCONFIG } from "../../app-config"
import { useWidgetData } from "../../utils/common"
import { IPlaidLinkOnSuccessMetadataFixed } from "../../utils/types/plaid"
import toast from "react-hot-toast"

export default function PlaidLink() {
  const nav = useNavigate()
  const [plaidToken, setPlaidToken] = useState("")
  const { widgetDataJsonObject } = useWidgetData()
  const createTokenCalled = useRef<boolean>(false)
  useEffect(() => {
    const accessToken = localStorage.getItem(APPCONFIG.localVars.token)
    if (!accessToken) {
      window.location.href = widgetDataJsonObject.redirectURL
      return
    }

    if (createTokenCalled.current) return
    plaidAuthTokenLink().then(
      (response) => setPlaidToken(response.payload.link_token),
      () => toast.error("An error ocurred while trying to create a plaid token link")
    )
    createTokenCalled.current = true
  }, [])

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: (_, metadata: IPlaidLinkOnSuccessMetadataFixed) => {
      if (!metadata.account || !metadata.public_token) {
        toast.error("Cannot create account details with metadata provided")
        return
      }
      plaidCreateAccountDetails({
        public_token: metadata.public_token,
        account_id: metadata.account?.id,
        account_mask: metadata.account.mask,
        account_name: metadata.account.name,
        account_type: metadata.account.type,
        account_subtype: metadata.account.subtype,
        account_verification_status: metadata.account.verification_status
      }).then(
        () => nav(`/${APPCONFIG.routes.backToArticle}`),
        () => toast.error("There was a error while creating plaid account details")
      )
    }
  })

  return (
    <div className="plaid-link">
      <PlaidContainer>
        <Container className="px-95 text-center mt-5">
          <p className="font-medium-24">Connect your bank account with Plaid</p>
          <img src={svg.AppToBank} alt="App to Bank" className="mt-5" />
          <Container className="w-75 mt-5 text-start">
            <p className="m-bottom-11 font-extra-bold-18">
              <img src={svg.Checkmark} alt="" />
              <span className="p-left-12">Secure</span>
            </p>
            <p className="m-bottom-11 font-normal-discription-16 p-left-36">
              All bank communications are encrypted end to end.
            </p>
            <p className="m-bottom-11 font-extra-bold-18">
              <img src={svg.Checkmark} alt="" />
              <span className="p-left-12">Private</span>
            </p>
            <p className="m-bottom-11 font-normal-discription-16 p-left-36">
              We will never see or save your bank account information
            </p>
          </Container>
        </Container>

        <Container className="fix-bottom px-95">
          <Button
            className="rounded-pill font-bold-14 text-white plaid-btn"
            onClick={() => {
              open()
            }}
            disabled={!ready}
          >
            Continue
          </Button>
        </Container>
      </PlaidContainer>
    </div>
  )
}
