import Logo from "./Logo.svg"
import Article from "./Article.svg"
import LoginWith from "./LoginWith.svg"
import Slide1 from "./Slide1.svg"
import Slide2 from "./Slide2.svg"
import Stripe from "./Stripe.svg"
import WelcomeBack from "./WelcomeBack.svg"
import SliderFillerForMobile from "./SliderFillerForMobile.svg"
import NbblLogoBlack from "./NbblLogoBlack.svg"
import AppToBank from "./AppToBank.svg"
import Checkmark from "./Checkmark.svg"
import AddImage from "./AddImage.svg"
import FacebookLogo from "./FacebookLogo.svg"
import AppleLogo from "./AppleLogo.svg"
import GoogleLogo from "./GoogleLogo.svg"
import ArrowUpFill2 from "./ArrowUpFill2.svg"
import CopyRegular from "./CopyRegular.svg"
import Information from "./information.png"
import ArrowLeft from "./carat.svg"
import ArrowRigth from "./ArrowRigth.svg"
export default {
  logo: Logo,
  artical: Article,
  loginWith: LoginWith,
  slide1: Slide1,
  slide2: Slide2,
  welcomeBack: WelcomeBack,
  sliderFillerForMobile: SliderFillerForMobile,
  NbblLogoBlack: NbblLogoBlack,
  AppToBank: AppToBank,
  Checkmark: Checkmark,
  AddImage: AddImage,
  FacebookLogo: FacebookLogo,
  AppleLogo: AppleLogo,
  GoogleLogo: GoogleLogo,
  Stripe: Stripe,
  ArrowUpFill2: ArrowUpFill2,
  CopyRegular: CopyRegular,
  Information: Information,
  ArrowLeft: ArrowLeft,
  ArrowRigth: ArrowRigth
}
