import { Col, Container } from "react-bootstrap"
import svg from "../../../assets/images/svg"
import FillerForRegistration from "../../../components/FillerForRegistration"
import RegistrationHeader from "../../../components/RegistrationHeader"
import RegisterContainer from "../../../customComponents/RegisterContainer"
import "./SiteRegistration.scss"
import RegistrationForm from "./components/RegistrationForm"
import { useParams } from "react-router-dom"

const SiteRegistration = () => {
  const { apiKey } = useParams()

  return (
    <div className="registration">
      <RegisterContainer>
        <Col className="registration-form" lg={8}>
          <RegistrationHeader />
          <div className="w-100 my-5 d-sm-none" />
          <Container className="w-75 on-mobile-w-100">
            <div className="text-center">
              <h4 className="w-100">Continue registration completing your</h4>
            </div>
            <RegistrationForm apiKey={apiKey ?? ""} />
          </Container>
        </Col>

        <Col className="registration-slider d-lg-block d-none" lg={4}>
          <FillerForRegistration slider={svg.slide1}>
            <p>Welcome to the #1 Publisher's Paywall</p>
          </FillerForRegistration>
        </Col>
      </RegisterContainer>
    </div>
  )
}
export default SiteRegistration
